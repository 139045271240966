import React from 'react';
import classnames from 'classnames';

export default function ChosenThumbnail({thumbnail, xPosition, yPosition}) {
    return (
        <div className="video-add-grid__video">
            <img 
                className={classnames("video-add-grid__thumbnail", "video-add-grid__thumbnail--chosen")} 
                src={thumbnail} 
                alt="chosen thumbnail" 
                style={{
                    objectPosition: `${xPosition}px ${yPosition}px`
                }}/>
            <div className="video-add-grid__videoButton">
                <svg
                    stroke="currentColor" 
                    fill="currentColor" 
                    strokeWidth="0" 
                    viewBox="0 0 24 24" 
                    height="1em"
                    xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 5v14l11-7z" />
                </svg>
            </div>    
        </div>
    )
}