import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import thunk from 'redux-thunk';
import authenticationReducer from '../store/reducers/authenticationReducers/reducers';
import uiReducer from '../store/reducers/uiReducers/reducers';
import videoReducer from '../store/reducers/videoReducers/reducers';

const rootReducer = combineReducers({
  authenticationReducer: authenticationReducer,
  uiReducer: uiReducer,
  videoReducer: videoReducer
});

export const store = createStore(rootReducer, compose(applyMiddleware(thunk)));