import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { MobileEndSearchIcon } from '../../utils/SVGConstants';

import {isBlank} from '../../utils/CommonUtils';
import {POST_SEARCH_URL} from '../../utils/Constants';

import {choseVideoAction} from '../../store/actions/videoActions/actions';
import * as actions from '../../store/actions/uiActions/actions';

import axios from '../../utils/Axios';
import classnames from 'classnames';

const ActiveMobileSearch = props => {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState(null);
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.authenticationReducer.isLoggedIn);

    useEffect(() => {
        const desktopMql = window.matchMedia('(min-width: 40rem)');

        const handleMQChangeToMobile = (evt) => {
            dispatch(actions.searchMobileHideEvent());
        };

        desktopMql.addEventListener('change', handleMQChangeToMobile);

        return () => { 
            desktopMql.removeEventListener('change', handleMQChangeToMobile);
        };
        // eslint-disable-next-line
    }, []);

    const onChange = (evt) => {
        setSearchTerm(evt.target.value);

        if(isBlank(evt.target.value)) {
            setResults(null);

            return;
        }

        const params = new URLSearchParams();

        params.append('term', evt.target.value);

        axios.post(POST_SEARCH_URL, params).then(response => {
            if(response.data.length === 0) {
                setResults(null);

                return;
            }

            setResults(response.data.map((searchResult, index) => {
                return (
                    <li 
                        key={index}
                        className="mobile-search-results__item" onClick={evt => {
                        setSearchTerm(searchResult.name);
                        setResults(null);
                        dispatch(choseVideoAction({videoClicked: true, ...searchResult}));
                        dispatch(actions.searchMobileHideEvent());

                        if(props.onComplete) {
                            props.onComplete(searchResult);
                        }
                    }}>{searchResult.name}</li>
                );
            }));
        }).catch(error => {
            console.log(error);
        })
    }

    let searchResults = null;
    let mobileSearchClass = classnames('mobile-search__input');

    if(results) {
        searchResults = (
            <ul className="mobile-search-results">
                {results}
            </ul>
        );
    }

    if(!isLoggedIn) {
        mobileSearchClass = classnames('mobile-search__input', 'mobile-search__input--disable');
    }

    return (
        <React.Fragment>
                <section className="mobile-search-wrapper">
                    <div className="mobile-search">
                        <MobileEndSearchIcon />
                        <input 
                            type="text" 
                            className={mobileSearchClass} 
                            placeholder="Enter search term"
                            value={searchTerm}
                            onChange={onChange} />
                        <i className="fa fa-search" aria-hidden="true"></i>
                    </div>
                    {searchResults}
                </section>
            </React.Fragment>
    );
}

export default ActiveMobileSearch;