import React, {useEffect, useState} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as authActions from '../../store/actions/authenticationActions/actions';
import * as uiActions from '../../store/actions/uiActions/actions';
import * as SVGConstants from '../../utils/SVGConstants';
import * as URLActions from '../../utils/Constants';

import Logout from '../../components/Button/Button';
import axios from '../../utils/Axios';

import famtubeicon from '../../imgs/famtube.png';
import classnames from 'classnames';

function SideBar({ sidePanelClass }) {
    const [hasPendingTasks, setHasPendingTasks] = useState(false);
    const [hasTrash, setHasTrash] = useState(false);
    const dispatch = useDispatch();
    const isStaff = useSelector(state => state.authenticationReducer.is_staff);
    const didChoosePendingTask = useSelector(state => state.uiReducer.didChoosePendingTask);
    const didChooseTrash = useSelector(state => state.uiReducer.didChooseTrash);
    const isLoggedIn = useSelector(state => state.authenticationReducer.isLoggedIn || Boolean(localStorage.getItem('loggedIn')));

    useEffect(() => {
        if(!isLoggedIn) {
            return;
        }

        axios.get(URLActions.GET_HAS_PENDING_TASKS)
        .then((response) => {
            setHasPendingTasks(response.data.hasPending);
        })
        .catch(error => {
            setHasPendingTasks(false);
        });

        axios.get(URLActions.GET_HAS_TRASH)
        .then((response) => {
            setHasTrash(response.data.hasTrash);
        })
        .catch(error => {
            setHasTrash(false);
        });
    });

    function logout() {
        axios.post(URLActions.LOGOUT_URL).then(response => {
            dispatch(authActions.logoutEventAction());
        }).catch(error => {
            console.error(error);

            dispatch(authActions.logoutEventAction());
        });
    }

    function uploadClicked(evt) {
        dispatch(uiActions.showUploadEvent());
    }

    let uploadVideo = (
        <div className={classnames("svg-container", "svg-container--moveRight")} onClick={uploadClicked}>
            <i className="fa fa-cloud-upload"></i> <span className="svg-container__text">Upload Video</span>
        </div>
    ); 

    if(!isStaff) {
        uploadVideo = null;
    }

    let pendingCallback = null;
    let trashCallback = null;
    let pendingNotificationIconClasses = null;
    let trashNotificationClasses = null;

    if(hasPendingTasks) {
        pendingNotificationIconClasses = classnames("status-notification__icon", "status-notification__icon--red");
        pendingCallback = () => {
            dispatch(uiActions.sidePanelHideEvent());
            dispatch(uiActions.didChoosePendingTaskEvent());
        };
    }

    if(hasTrash) {
        trashNotificationClasses = classnames("status-notification__icon", "status-notification__icon--red");
        trashCallback = () => {
            dispatch(uiActions.didChooseTrashEvent());
        };
    }

    if(didChoosePendingTask && hasPendingTasks) {
        //https://stackoverflow.com/questions/52064303/reactjs-pass-props-with-redirect-component
        return (
            <Redirect to={{
              pathname: "/pending-videos"
            }}/>
        );
    }

    if(didChooseTrash && hasTrash) {
        //https://stackoverflow.com/questions/52064303/reactjs-pass-props-with-redirect-component
        return (
            <Redirect to={{
              pathname: "/trash"
            }}/>
        );
    }

    return (
        <aside className={sidePanelClass}>
            <div className="side-panel__header">
                <div className="toggle" onClick={(evt) => dispatch(uiActions.sidePanelHideEvent())}>
                    {SVGConstants.TOGGLE_BUTTON}
                </div>
                <div className="side-panel__header__nested_container">
                    <Link className="anchor-img-container" to="/">
                        <img src={famtubeicon} alt="FamTube Icon" />
                    </Link>
                </div>
            </div>

            <div className="horizontal-splitter" />

            <div className="svg-container svg-container__side-bar">
                {SVGConstants.HOME_ICON}
                <span>Home</span>
            </div>

            <div className="svg-container svg-container__side-bar">
                {SVGConstants.EXPLORE_ICON}
                <span>Explore</span>
            </div>

            <div className="svg-container svg-container__side-bar">
                {SVGConstants.HISTORY_ICON}
                <span>History</span>
            </div>

            <div className="svg-container svg-container__side-bar" onClick={pendingCallback}>
                <div className="status-notification">
                    <div className={pendingNotificationIconClasses}></div>
                    {SVGConstants.PENDING}
                </div>
                <span>Pending</span>
            </div>

            <div className="svg-container svg-container__side-bar" onClick={trashCallback}>
                <div className="status-notification">
                    <div className={trashNotificationClasses}></div>
                    {SVGConstants.DELETE_FOREVER}
                </div>
                <span>Trash</span>
            </div>

            {uploadVideo}

            <div className="horizontal-splitter" />

            <Logout
                containerClassNames="login login--center"
                buttonClassNames="login__button"
                text="Logout"
                clickHandler={logout} />
        </aside>
    );
}

export default SideBar;