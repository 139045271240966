import React, { useState } from 'react';
import {useDispatch} from 'react-redux';

import * as uiActions from '../../store/actions/uiActions/actions';
import {EVENT_STATE_ERROR} from '../../utils/Constants';
import {isBlank} from '../../utils/CommonUtils';

export default function UploadForm(props) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);
    const [category, setCategory] = useState('');
    const [channel, setChannel] = useState('');
    const dispatch = useDispatch();

    const onSave = evt => {
        if(isBlank(name) || isBlank(description)) {
            dispatch(uiActions.alertLoadOverlayEventAction("Name and description required", EVENT_STATE_ERROR));

            return;
        }

        if(props.onSave) {
            props.onSave(name, description, isPrivate, category, channel);
        }
    }

    const onCancel = evt => {
        if(props.onCancel) {
            props.onCancel();
        }
    }

    return (
        <React.Fragment>
            <div className="video-uploadform">
                <h3>Enter Video details</h3>
                <label className="video-uploadform__labelname" htmlFor="name">Name</label>
                <input className="video-uploadform__name" type="text" name="name" value={name} onChange={evt => setName(evt.target.value)}/>

                <label className="video-uploadform__labeldescription" htmlFor="description">Description</label>
                <textarea className="video-uploadform__description" name="description" value={description} onChange={evt => setDescription(evt.target.value)}/>

                <label className="video-uploadform__labelisprivate" htmlFor="name">Is Private?</label>
                <input className="video-uploadform__isprivate" type="checkbox" name="is_private" value={isPrivate} onChange={evt => setIsPrivate(evt.target.checked)}/>

                <label className="video-uploadform__labelcategory" htmlFor="name">Category</label>
                <input className="video-uploadform__category" type="text" name="category" value={category} onChange={evt => setCategory(evt.target.value)}/>

                <label className="video-uploadform__labelchannel" htmlFor="name">Channel</label>
                <input className="video-uploadform__channel" type="text" name="channel" value={channel} onChange={evt => setChannel(evt.target.value)}/>

                <button className="video-uploadform__save" onClick={onSave}>Save</button> <button className="video-uploadform__cancel" onClick={onCancel}>Cancel</button>
            </div>
        </React.Fragment>
    );
};