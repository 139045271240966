import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import * as URLActions from '../../utils/Constants';
import axios from '../../utils/Axios';


function NewPendingVideo(props) {
    const [videos, setVideos] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [vidId, setVidId] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [thumbnailUrls, setThumbnailUrls] = useState(null);

    useEffect(() => {
        axios.get(URLActions.GET_PENDING_VIDEOS)
        .then((response) => {
            const data = [];

            response.data.forEach((item, index) => {
                const vidMarkUp = (
                    <div 
                        key={index}
                        className="video-previews__item__preview-static" 
                        onClick={evt => {
                            setVidId(item.id);
                            setVideoUrl(item.video_url);
                            setThumbnailUrls(item.related_thumbnails.map(tn => tn.thumbnail_url));
                            setRedirect(true);
                        }}>
                        <img 
                            className="video-previews__item__thumbnail" 
                            src={item.video_thumbnail_url}
                            alt="thumbnail choice" />
                    </div>
                );
                
                data.push({
                    markUp: vidMarkUp,
                    item: item
                });
            });

            setVideos(data);
        })
        .catch(error => {
            console.log(error);
        });
    }, []);
    
    const content = videos.map(dictionary => dictionary.markUp);

    if(redirect) {
        //https://stackoverflow.com/questions/52064303/reactjs-pass-props-with-redirect-component
        return (
          <Redirect to={{
            pathname: "/video-page/add",
            state: { vidId: vidId, videoUrl: videoUrl, thumbnails: thumbnailUrls }
          }}/>
        );
    }

    return (
        <React.Fragment>
            <h1>Pending Videos</h1>
            <section className="new-content">
                {content}
            </section>
        </React.Fragment>
    );
}

export default NewPendingVideo;