import React from 'react';
import {Switch, Route} from 'react-router-dom';

import Layout from './containers/Layout/Layout';
import HomePage from './containers/HomePage/HomePage';
import TrashPage from './containers/TrashPage/TrashPage';
import VideoPage from './containers/VideoPage/VideoPage';
import LoginPage from './containers/LoginPage/LoginPage';
import NewVideoPage from './containers/NewVideoPage/NewVideoPage';
import NewPendingVideo from './containers/NewVideoPage/NewPendingVideos';
import ShareVideo from './components/ShareVideo/ShareVideo';

function App(props) {
  return (
    <Layout>
      <Switch>
        <Route exact path="/pending-videos" component={NewPendingVideo}/>
        <Route exact path="/video-page/add" component={NewVideoPage}/>
        <Route exact path="/video-page/:id" component={VideoPage} />
        <Route exact path="/share/:shareUrl" component={ShareVideo} />
        <Route exact path="/login-page" component={LoginPage}/>
        <Route path="/trash" component={TrashPage} />
        <Route path="/" component={HomePage} />
      </Switch>
    </Layout>
  );
}

export default App;
