import * as authActionTypes from '../authenticationActions/actionTypes';

export const loginEventAction = (username, is_admin, is_staff) => {
    localStorage.setItem('loggedIn', true);
    localStorage.setItem('username', username);
    localStorage.setItem('is_admin', Boolean(is_admin));
    localStorage.setItem('is_staff', Boolean(is_staff));

    return {
        type: authActionTypes.LOGIN_EVENT,
        username: username,
        is_admin: is_admin,
        is_staff: is_staff
    }
};

export const logoutEventAction = () => {
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('username');
    localStorage.removeItem('is_admin');
    localStorage.removeItem('is_staff');

    return {
        type: authActionTypes.LOGOUT_EVENT
    }
}