import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as uiActions from '../../store/actions/uiActions/actions';

import classnames from 'classnames';

import axios from '../../utils/Axios';
import * as URLConstants from '../../utils/Constants';
import {EVENT_STATE_ERROR} from '../../utils/Constants';


import processingGif from '../../imgs/processing.gif';

function Upload(props) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [enterStyle, setEnterStyle] = useState(null);

    const showUpload = useSelector(state => state.uiReducer.showUpload);
    const dispatch = useDispatch();
    const fileElement = React.createRef();

    const className = showUpload ? classnames("custom-file-upload", "custom-file-upload--show") : classnames("custom-file-upload");
    const fileName = selectedFile ? selectedFile.name : null;

    useEffect(() => {
        if(hasError) {
            setSelectedFile(null);
        }
    }, [hasError]);

    function submit(evt) {
        if(!selectedFile) {
            return;
        }

        setUploading(true);

        //https://www.geeksforgeeks.org/file-uploading-in-react-js/
        const formData = new FormData();

        formData.append(
            "file",
            selectedFile,
            selectedFile.name
        );
        formData.append("file_name", selectedFile.name);

        axios.post(URLConstants.POST_UPLOAD, formData, {timeout: 300000}).then(response => {
            dispatch(uiActions.hideUploadEvent());
            dispatch(uiActions.wasUploaded(true));
            
            setUploading(false);
            setHasError(false);
        }).catch(error => {
            dispatch(uiActions.alertLoadOverlayEventAction("Failed to upload video, try again", EVENT_STATE_ERROR));
            dispatch(uiActions.wasUploaded(false));

            setUploading(false);
            setHasError(true);
        });
    }

    const disableStyle = {background: 'inherit', color: 'inherit'};
    const processing = uploading ? (
        <div className="processing-gif">
            <img src={processingGif} alt="Processing gif" />
        </div>
    ) : null;

    let fileClassNames = classnames("custom-file-upload__fileName");

    if(enterStyle) {
        fileClassNames = classnames("custom-file-upload__fileName", "custom-file-upload__fileName--expand");
    }

    return (
        <React.Fragment>
            <div className={className}>
                <span className="custom-file-upload__close" onClick={() => dispatch(uiActions.hideUploadEvent())}>X</span>
                <span className="custom-file-upload__text">Choose video to upload</span>
                
                {processing}

                <button 
                    className='custom-file-upload__chooseVideo'
                    disabled={uploading}
                    style={uploading ? disableStyle : null}
                    onClick={(evt) => {
                    fileElement.current.click();
                }}>
                    Choose Video
                </button>
                <input
                    ref={fileElement}
                    className="custom-file-upload__fileUpload"
                    type="file"
                    name="filename"
                    accept="video/*"
                    onChange={(evt) => setSelectedFile(evt.target.files[0])} />
                <button className='custom-file-upload__uploadButton' disabled={uploading} style={uploading ? disableStyle : null} onClick={submit}>
                    <i className="fa fa-cloud-upload"></i> Upload Video
                </button>
                <p 
                    className={fileClassNames}
                    onMouseEnter={() => setEnterStyle(true)}
                    onMouseLeave={() => setEnterStyle(false)}>File name: {fileName}</p>
            </div>
        </React.Fragment>
    );
}

export default Upload;