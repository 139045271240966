import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import '../src/scss/famtube.css';
import App from './App';
import { createBrowserHistory } from "history";

//https://daveceddia.com/access-redux-store-outside-react/
import { store } from './storeBuilder/store';

export const appHistory = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={appHistory}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
