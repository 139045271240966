import React from 'react';

function CommentHeader(props) {
    return (
        <React.Fragment>
            <div className="comments">
                <div className="comments__content">
                    <p className="comments__title">Comments</p>
                    <p className="comments__number">{props.commentCount}</p>
                </div>
                <div className="comments__activate-icon" onClick={props.onCommentClicked}>
                    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M12,21.7l-6.4-6.4l0.7-0.7l5.6,5.6l5.6-5.6l0.7,0.7L12,21.7z M18.4,8.6L12,2.3L5.6,8.6l0.7,0.7L12,3.7l5.6,5.6L18.4,8.6z"></path>
                    </svg>
                </div>
            </div>
            <div className="horizontal-splitter" />
        </React.Fragment>
    );
}

export default CommentHeader