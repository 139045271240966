import React, {useState, useEffect} from 'react';
import * as URLConstants from '../../utils/Constants';

import VideoPreview from '../../components/VideoPreview/VideoPreview';
import axios from '../../utils/Axios';

import TrashOptions from '../../components/TrashOptions/TrashOptions';
import withAuthRedirect from '../../hoc/withAuthRedirect';

function TrashPage(props) {
    const [previews, setPreviews] = useState([]);
    const [needsRefresh, setNeedRefresh] = useState(false);
    const getContent = () => {
        axios.get(URLConstants.GET_TRASH).then(response => {
            setPreviews(response.data.trash.map(video => {
                const trashOption = (<TrashOptions 
                    id={video.id} 
                    onRecoverVideo={() => setNeedRefresh(true)}/>
                );

                return (
                    <VideoPreview 
                        key={video.id} 
                        {...video} 
                        optionsComponent={trashOption}
                        isTrash/>
                );
            }));
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        getContent();
    }, []);

    useEffect(() => {
        if(!needsRefresh) {
            return;
        }

        getContent();
    }, [needsRefresh]);

    return (
        <React.Fragment>
            <h1>Trash</h1>
            <section className="home-page">
                {previews}
            </section>
        </React.Fragment>
    );
}

export default withAuthRedirect(TrashPage);