import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as uiActions from '../../store/actions/uiActions/actions';

import * as SVGConstants from '../../utils/SVGConstants';
import {EVENT_STATE_ERROR, EVENT_STATE_WARNING} from '../../utils/Constants';

import classnames from 'classnames';

const AlertNotification = (props) => {
    const message = useSelector(state => state.uiReducer.message);
    const showAlert = useSelector(state => state.uiReducer.showAlertMsg);
    const eventState = useSelector(state => state.uiReducer.eventState);
    const dispatch = useDispatch();
    const isInError = eventState === EVENT_STATE_WARNING || eventState === EVENT_STATE_ERROR;
    const classes = isInError ? classnames("alert-notification", "alert-notification--show-danger") 
    : classnames("alert-notification", "alert-notification--show-success");
    
    const face = isInError ? SVGConstants.DISASTER_FACE : SVGConstants.HAPPY_FACE;

    return (
        <React.Fragment>
            {showAlert &&
                <div className={classes}>
                    <p>{message}</p>
                    <div className="svg-container">
                        {face}
                    </div>
                    <button className="login__button" onClick={() => dispatch(uiActions.alertUnloadOverlayEventAction())}>Close</button>
                </div>
            }
        </React.Fragment>

    );
}

export default AlertNotification;