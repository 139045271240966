import React from 'react';
import { useDispatch } from 'react-redux';

import * as uiActions from '../store/actions/uiActions/actions';

export const MobileEndSearchIcon = (props) => {
    const dispatch = useDispatch();

    return (
        <svg
            className="mobile-search__endsearch"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            height="24"
            width="24"
            onClick={() => dispatch(uiActions.searchMobileHideEvent())}>
            <path d="M21,11v1H5.64l6.72,6.72l-0.71,0.71L3.72,11.5l7.92-7.92l0.71,0.71L5.64,11H21z" />
        </svg>
    );
};

export const WhiteThumbsUp = props => (
    <svg
        className="video-action"
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        onClick={props.onClick}>
        <path d="M18.77,11h-4.23l1.52-4.94C16.38,5.03,15.54,4,14.38,4c-0.58,0-1.14,0.24-1.52,0.65L7,11H3v10h4h1h9.43 c1.06,0,1.98-0.67,2.19-1.61l1.34-6C21.23,12.15,20.18,11,18.77,11z M7,20H4v-8h3V20z M19.98,13.17l-1.34,6 C18.54,19.65,18.03,20,17.43,20H8v-8.61l5.6-6.06C13.79,5.12,14.08,5,14.38,5c0.26,0,0.5,0.11,0.63,0.3 c0.07,0.1,0.15,0.26,0.09,0.47l-1.52,4.94L13.18,12h1.35h4.23c0.41,0,0.8,0.17,1.03,0.46C19.92,12.61,20.05,12.86,19.98,13.17z"></path>
    </svg>
);

export const WhiteThumbsDown = props => (
    <svg
        className="video-action"
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        onClick={props.onClick}>
        <path d="M17,4h-1H6.57C5.5,4,4.59,4.67,4.38,5.61l-1.34,6C2.77,12.85,3.82,14,5.23,14h4.23l-1.52,4.94C7.62,19.97,8.46,21,9.62,21 c0.58,0,1.14-0.24,1.52-0.65L17,14h4V4H17z M10.4,19.67C10.21,19.88,9.92,20,9.62,20c-0.26,0-0.5-0.11-0.63-0.3 c-0.07-0.1-0.15-0.26-0.09-0.47l1.52-4.94l0.4-1.29H9.46H5.23c-0.41,0-0.8-0.17-1.03-0.46c-0.12-0.15-0.25-0.4-0.18-0.72l1.34-6 C5.46,5.35,5.97,5,6.57,5H16v8.61L10.4,19.67z M20,13h-3V5h3V13z"></path>
    </svg>
);

export const BlackThumbsUp = props => (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 512.001 512.001" height="24" width="24" enableBackground="new 0 0 24 24">
        <g>
            <g>
                <path d="M406.296,90.17h-30.059c-8.307,0-15.03,6.722-15.03,15.03c0,8.307,6.722,15.03,15.03,15.03h30.059
			c8.307,0,15.03-6.722,15.03-15.03C421.325,96.892,414.603,90.17,406.296,90.17z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M254.416,38.359L239.387,8.3c-3.742-7.427-12.74-10.406-20.167-6.722c-7.427,3.713-10.436,12.74-6.722,20.167
			l15.03,30.059c3.708,7.397,12.69,10.436,20.167,6.722C255.12,54.813,258.129,45.786,254.416,38.359z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M352.9,1.578c-7.398-3.684-16.453-0.704-20.167,6.722l-15.03,30.059c-3.713,7.427-0.704,16.453,6.722,20.167
			c7.499,3.724,16.47,0.65,20.167-6.722l15.03-30.059C363.335,14.318,360.326,5.291,352.9,1.578z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M195.882,90.17h-30.059c-8.307,0-15.03,6.722-15.03,15.03c0,8.307,6.722,15.03,15.03,15.03h30.059
			c8.307,0,15.03-6.722,15.03-15.03C210.912,96.892,204.189,90.17,195.882,90.17z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M105.705,241.468H45.586c-8.3,0-15.03,6.729-15.03,15.03V496.97c0,8.3,6.729,15.03,15.03,15.03h60.118
			c8.3,0,15.03-6.729,15.03-15.03V256.497C120.734,248.197,114.006,241.468,105.705,241.468z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M481.145,281.308c-2.651-23.315-24.43-39.84-47.895-39.84H316.075c9.958-17.831,15.303-68.264,15.066-88.866
			c-0.393-34.102-28.634-61.43-62.737-61.43h-12.402c-8.307,0-15.03,6.722-15.03,15.03c0,34.755-13.533,97.487-39.056,123.011
			c-17.179,17.179-31.865,23.404-51.122,33.028v225.518c29.483,9.827,66.917,24.242,123.974,24.242h98.316
			c32.395,0,57.625-30.003,45.072-61.703c19.125-5.21,33.229-22.75,33.229-43.504c0-5.857-1.13-11.463-3.17-16.615
			c32.229-8.781,44.092-48.576,21.723-73.563C478.156,307.435,482.688,294.877,481.145,281.308z"/>
            </g>
        </g>
    </svg>
);

export const BlackThumbsDown = props => (
    <svg enableBackground="new 0 0 24 24" height="17" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="m22.25 1h-2.5c-.965 0-1.75.785-1.75 1.75v11.5c0 .965.785 1.75 1.75 1.75h2.5c.965 0 1.75-.785 1.75-1.75v-11.5c0-.965-.785-1.75-1.75-1.75z" />
        <path d="m5.119.75c-1.95 0-3.61 1.4-3.94 3.32l-1.12 6.5c-.42 2.45 1.46 4.68 3.94 4.68h4.72s-.75 1.5-.75 4c0 3 2.25 4 3.25 4s1.5-.5 1.5-3c0-2.376 2.301-4.288 3.781-5.273v-12.388c-1.601-.741-4.806-1.839-9.781-1.839z" />
    </svg>
);

export const Download = props => (
    <svg
        className="video-action__image-noheight"
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        onClick={props.onClick}>
        <path d="M11 21.883l-6.235-7.527-.765.644 7.521 9 7.479-9-.764-.645-6.236 7.529v-21.884h-1v21.883z" />
    </svg>
);

export const Report = props => (
    <svg
        className="video-action"
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        onClick={props.onClick}>
        <path d="M13.18,4l0.24,1.2L13.58,6h0.82H19v7h-5.18l-0.24-1.2L13.42,11H12.6H6V4H13.18 M14,3H5v18h1v-9h6.6l0.4,2h7V5h-5.6L14,3 L14,3z"></path>
    </svg>
);

export const SHARE = (
    <svg className="video-action" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M15,5.63L20.66,12L15,18.37V15v-1h-1c-3.96,0-7.14,1-9.75,3.09c1.84-4.07,5.11-6.4,9.89-7.1L15,9.86V9V5.63 M14,3v6 C6.22,10.13,3.11,15.33,2,21c2.78-3.97,6.44-6,12-6v6l8-9L14,3L14,3z"></path>
    </svg>
);

export const HOME_ICON = (
    <svg className="svg-container__icon" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false">
        <g className="style-scope yt-icon">
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8" className="style-scope yt-icon"></path>
        </g>
    </svg>
);

export const EXPLORE_ICON = (
    <svg className="svg-container__icon" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false">
        <g className="style-scope yt-icon">
            <path d="M11.23 13.08c-.29-.21-.48-.51-.54-.86-.06-.35.02-.71.23-.99.21-.29.51-.48.86-.54.35-.06.7.02.99.23.29.21.48.51.54.86.06.35-.02.71-.23.99a1.327 1.327 0 01-1.08.56c-.28 0-.55-.08-.77-.25zM22 12c0 5.52-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2s10 4.48 10 10zm-3.97-6.03L9.8 9.8l-3.83 8.23 8.23-3.83 3.83-8.23z" className="style-scope yt-icon">
            </path>
        </g>
    </svg>
);

export const HISTORY_ICON = (
    <svg className="svg-container__icon" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false">
        <g>
            <path d="M11.9 3.75c-4.55 0-8.23 3.7-8.23 8.25H.92l3.57 3.57.04.13 3.7-3.7H5.5c0-3.54 2.87-6.42 6.42-6.42 3.54 0 6.4 2.88 6.4 6.42s-2.86 6.42-6.4 6.42c-1.78 0-3.38-.73-4.54-1.9l-1.3 1.3c1.5 1.5 3.55 2.43 5.83 2.43 4.58 0 8.28-3.7 8.28-8.25 0-4.56-3.7-8.25-8.26-8.25zM11 8.33v4.6l3.92 2.3.66-1.1-3.2-1.9v-3.9H11z"></path>
        </g>
    </svg>
);

export const HAPPY_FACE = (
    <svg className="svg-container__icon" viewBox="0 0 121.669 121.669" enableBackground="new 0 0 121.669 121.669">
        <g>
            <path d="M60.834,121.669C27.291,121.669,0,94.379,0,60.834C0,27.29,27.291,0,60.834,0s60.834,27.29,60.834,60.834   C121.669,94.379,94.378,121.669,60.834,121.669z M60.834,6C30.599,6,6,30.599,6,60.834c0,30.236,24.599,54.835,54.834,54.835   s54.834-24.599,54.834-54.835C115.669,30.599,91.07,6,60.834,6z" />
            <path d="M60.638,100.436c-10.041,0-18.209-7.281-18.209-16.231h6c0,5.642,5.477,10.231,12.209,10.231   c6.731,0,12.207-4.59,12.207-10.231h6C78.844,93.154,70.676,100.436,60.638,100.436z" />
            <rect x="17.101" y="56.835" width="19.07" height="6" />
            <rect x="85.1" y="56.835" width="19.07" height="6" />
        </g>
    </svg>
);

export const DISASTER_FACE = (
    <svg className="svg-container__icon" viewBox="0 0 121.669 121.669" enableBackground="new 0 0 121.669 121.669">
        <g>
            <path d="M60.834,121.669C27.291,121.669,0,94.379,0,60.834C0,27.29,27.291,0,60.834,0s60.834,27.29,60.834,60.834   C121.669,94.379,94.378,121.669,60.834,121.669z M60.834,6C30.599,6,6,30.599,6,60.834c0,30.236,24.599,54.835,54.834,54.835   s54.834-24.599,54.834-54.835C115.669,30.599,91.07,6,60.834,6z" />
            <polygon points="68.421,76.206 68.387,76.241 68.352,76.206 60.834,83.724 53.316,76.206 53.281,76.241 53.247,76.206    41.492,87.962 45.735,92.204 53.283,84.656 60.831,92.204 60.834,92.2 60.839,92.204 68.387,84.656 75.936,92.204 80.178,87.961     " />
            <polygon points="98.361,45.285 94.118,41.042 88.005,47.155 81.891,41.042 77.648,45.285 83.762,51.398 77.648,57.512    81.891,61.755 88.005,55.642 94.118,61.755 98.361,57.512 92.249,51.398  " />
            <polygon points="43.621,45.285 39.378,41.042 33.265,47.155 27.149,41.042 22.907,45.285 29.021,51.398 22.907,57.512    27.149,61.755 33.265,55.642 39.378,61.755 43.621,57.512 37.508,51.398  " />
        </g>
    </svg>
);

export const WATCH_OPTIONS = (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
        <path d="M12,16.5c0.83,0,1.5,0.67,1.5,1.5s-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5S11.17,16.5,12,16.5z M10.5,12 c0,0.83,0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5s-0.67-1.5-1.5-1.5S10.5,11.17,10.5,12z M10.5,6c0,0.83,0.67,1.5,1.5,1.5 s1.5-0.67,1.5-1.5S12.83,4.5,12,4.5S10.5,5.17,10.5,6z"></path>
    </svg>
);

export const TOGGLE_BUTTON = (
    <svg className="toggle__item" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M3 6h18v2H3V6m0 5h18v2H3v-2m0 5h18v2H3v-2z"></path>
    </svg>
);

export const FILE_UPLOAD = (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <path d="M0 0h24v24H0z" fill="none" /><path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
    </svg>
);

export const FILE_DOWNLOAD = (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <g>
            <rect fill="none" height="24" width="24" />
        </g>
        <g>
            <path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z" />
        </g>
    </svg>
);

export const PENDING = (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 58 58" width="24px" fill="#000000">
        <path d="M25 1c-2.872 0-5.68.502-8.348 1.492l.696 1.875A21.921 21.921 0 0 1 25 3c12.131 0 22 9.869 22 22s-9.869 22-22 22S3 37.131 3 25a22.001 22.001 0 0 1 8-16.958V15h2V5H3v2h6.126A24.005 24.005 0 0 0 1 25c0 13.233 10.767 24 24 24s24-10.767 24-24S38.233 1 25 1z"/>
        <path d="M19 33h-2v2h16v-2h-2v-3.414L26.414 25 31 20.414V17h2v-2H17v2h2v3.414L23.586 25 19 29.586V33zm2-13.414V17h8v2.586l-4 4-4-4zm4 6.828l4 4V33h-8v-2.586l4-4zM19 39h2v2h-2zM24 39h2v2h-2zM29 39h2v2h-2z"/>
    </svg>
);

export const ADD_TO_WATCH_LIST = (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <g><rect fill="none" height="24" width="24"/></g>
        <g><path d="M14,10H3v2h11V10z M14,6H3v2h11V6z M18,14v-4h-2v4h-4v2h4v4h2v-4h4v-2H18z M3,16h7v-2H3V16z"/></g>
    </svg>
);

export const SOCIAL_SHARE = (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"/>
    </svg>
);

export const DELETE = (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/>
    </svg>
);

export const DELETE_FOREVER = (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M14.12 10.47L12 12.59l-2.13-2.12-1.41 1.41L10.59 14l-2.12 2.12 1.41 1.41L12 15.41l2.12 2.12 1.41-1.41L13.41 14l2.12-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"/>
    </svg>
);

export const RECOVER = (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M21 10h-8.01V7L9 11l3.99 4v-3H21v5H3V5h18v3h2V5c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2v-5H23c0-1.1-.9-2-2-2z"/>
    </svg>
);