import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { isBlank } from '../../utils/CommonUtils';
import { POST_SEARCH_URL } from '../../utils/Constants';

import {choseVideoAction} from '../../store/actions/videoActions/actions';
import {searchDesktopShowEvent, searchDesktopHideEvent} from '../../store/actions/uiActions/actions';

import classnames from 'classnames';
import axios from '../../utils/Axios';

function SearchOther(props) {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState(null);
    const showSearchDesktop = useSelector(state => state.uiReducer.showSearchDesktop);
    const isLoggedIn = useSelector(state => state.authenticationReducer.isLoggedIn);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!showSearchDesktop) {
            setResults(null);
        }
    }, [showSearchDesktop]);

    const onChange = (evt) => {
        setSearchTerm(evt.target.value);

        if (isBlank(evt.target.value)) {
            setResults(null);
            dispatch(searchDesktopHideEvent());

            return;
        }

        dispatch(searchDesktopShowEvent());
        const params = new URLSearchParams();

        params.append('term', evt.target.value);

        axios.post(POST_SEARCH_URL, params).then(response => {
            if (response.data.length === 0) {
                setResults(null);

                return;
            }

            setResults(response.data.map((searchResult, index) => {
                return (
                    <div 
                        key={index}
                        className="search-result-container" 
                        onClick={evt => {
                            setSearchTerm(searchResult.name);
                            setResults(null);
                            dispatch(searchDesktopHideEvent());
                            dispatch(choseVideoAction({videoClicked: true, ...searchResult}));

                            if (props.onComplete) {
                                props.onComplete(searchResult);
                            }
                        }}>
                        <div className="search-result-container__thumbnail">
                            <img src={searchResult.thumbnail} alt={searchResult.description} />
                        </div>
                        <li
                            className="search-results-container__item">
                                <span className="search-results-container__item__title">{searchResult.name}</span>
                                <span>{searchResult.description}</span>
                        </li>
                    </div>
                );
            }));
        }).catch(error => {
            console.log(error);
        })
    }

    let searchClass = classnames('search-results');
    let searchInput = classnames('search__input');

    if (searchTerm) {
        searchClass = classnames('search-results', 'search-results--show');
    }
    if(showSearchDesktop) {
        searchInput = classnames('search__input--elevate');
    }

    if(!isLoggedIn) {
        searchInput = classnames('search__input--disable');
    }
    
    let searchResults = null;

    if (results) {
        searchResults = (
            <ul className={searchClass}>
                {results}
            </ul>
        );
    }

    return (
        <React.Fragment>
            <div className="search">
                <input
                    className={searchInput}
                    type="text"
                    placeholder="Enter search term"
                    value={searchTerm}
                    onChange={onChange} 
                    onKeyUp={null}/>
                <i className="fa fa-search fa-desktop fa-2x" aria-hidden="true"></i>
                {searchResults}
            </div>
        </React.Fragment>
    );
}

export default SearchOther;