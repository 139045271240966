import React, {useEffect, useState, useRef} from 'react';
import {useSelector} from 'react-redux';

import VideoMetaData from '../VideoMetaData/VideoMetaData';
import { ReactVideo } from "reactjs-media";

import {isBlank} from '../../utils/CommonUtils';

function Video(props) {
    let content = <VideoMetaData {...props}/>;
    const videoId = useSelector(state => state.videoReducer.id);
    const [supportsMimeType, setSupportsMimeType] = useState(true);
    const emptyVideoRef = useRef(null);

    useEffect(() => {
        if(emptyVideoRef.current) {
            const canPlay = !isBlank(emptyVideoRef.current.canPlayType(props.mimetype));

            console.log(`Can play? ${canPlay}`);
            
            setSupportsMimeType(canPlay);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const videoDiv = document.querySelector('.poster > div');

        if(videoDiv) {
            videoDiv.style.backgroundImage = `url(${props.thumbnail})`;
            videoDiv.style.backgroundPosition = `${props.xPosition}px ${props.yPosition}px`;
        }
        // eslint-disable-next-line
    }, [videoId]);

    if(!props.showDescription) {
        content = null;
    }

    if(isBlank(props.video)) {
        return null;
    }
    
    return (
        <React.Fragment>
            <div style={{display: 'none'}}>
                <video ref={emptyVideoRef}></video>
            </div>
            <div className="video-page__container">
                <ReactVideo 
                    key={props.id}
                    src={supportsMimeType ? props.video : props.alt_video}
                    type={supportsMimeType ? props.mimetype : "video/mp4"}
                    poster={props.thumbnail}
                    className='video-page__video'/>
            </div>
            {content}
            <div className="horizontal-splitter"/>
        </React.Fragment>
    );
}

export default Video;