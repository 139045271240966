import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {EVENT_STATE_SUCCESS, EVENT_STATE_ERROR} from '../../utils/Constants';
import * as URLConstants from '../../utils/Constants';

import * as uiActions from '../../store/actions/uiActions/actions'; 
import axios from '../../utils/Axios';

import {isBlank} from '../../utils/CommonUtils';

function CommentListHeader(props) {
    const [comment, setComment] = useState('');
    const chosenVideo = useSelector(state => {
        return {...state.videoReducer};
    });
    const dispatch = useDispatch();

    function onKeyDown(evt) {
        if(evt.key === "Enter" && !isBlank(comment)) {
            const params = new URLSearchParams();

            params.append('type_id', chosenVideo.id);
            params.append('type', 'video');
            params.append('comment', comment);

            axios.post(URLConstants.POST_MAKE_COMMENT, params)
            .then(response => {
                if(response.data.code === 1) {
                    setComment('')
                    props.onCommentEntered();
                    dispatch(uiActions.alertLoadOverlayEventAction('Comment added', EVENT_STATE_SUCCESS));
                }
                else {
                    dispatch(uiActions.alertLoadOverlayEventAction('Comment failed.. try again', EVENT_STATE_ERROR));
                }
            }).catch(error => {
                dispatch(uiActions.alertLoadOverlayEventAction('Comment failed.. try again', EVENT_STATE_ERROR));
            });
        }
    }

    return (
        <React.Fragment>
                <div className="user-comment__header">
                    <p>Comments</p>
                    <svg onClick={props.onCommentClicked} xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M12.71,12l8.15,8.15l-0.71,0.71L12,12.71l-8.15,8.15l-0.71-0.71L11.29,12L3.15,3.85l0.71-0.71L12,11.29l8.15-8.15l0.71,0.71 L12.71,12z"></path>
                    </svg>
                </div>
                
                <section className="user-add-comment">
                    <section className="user-comments__add-comment">
                        <div className="user-comments__user_image">
                            <img src={props.photo} alt="" />
                        </div>
                        <div className="user-comments__user-comment">
                            <input 
                                type="text" 
                                value={comment}
                                placeholder="Add a public comment"
                                onChange={evt => { 
                                    setComment(evt.target.value);
                                }}
                                onKeyDown={onKeyDown}/>
                        </div>
                    </section>
                </section>

                <section className="user-comments">
                    {props.comments}
                </section>
                
            </React.Fragment>
    );
}

export default CommentListHeader;