import React from 'react';

import {FILE_UPLOAD, FILE_DOWNLOAD} from '../../utils/SVGConstants';

import classnames from 'classnames';

function AdjustVideo({clicked, yPosition, setYPosition}) {
    return (
        <React.Fragment>
            <div 
                className={classnames("svg-container", "svg-container__uparrow")} 
                onClick={evt => {
                    evt.stopPropagation();
                                
                    setYPosition(yPosition + 1);
                }} 
                onDoubleClick={evt => {
                    evt.stopPropagation();
                
                    setYPosition(yPosition + 40);
                }}>
                {FILE_UPLOAD}
            </div>
            <div 
                className={classnames("svg-container", "svg-container__downarrow")} 
                onClick={evt => {
                    evt.stopPropagation();
                    
                    if(yPosition === 0) {
                        return;
                    }
                
                    setYPosition(yPosition - 1);
                }}
                onDoubleClick={evt => {
                    evt.stopPropagation();
                
                    if(yPosition === 0) {
                        return;
                    }
                
                    let position = yPosition - 40;
                
                    if(position < 0) {
                        position = 0;
                    }
                
                    setYPosition(position);
                }}>
                {FILE_DOWNLOAD}
            </div>
        </React.Fragment>
    )
}

export default AdjustVideo;