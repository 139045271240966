import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';

import {WATCH_OPTIONS} from '../../utils/SVGConstants';

import * as actions from '../../store/actions/videoActions/actions';
import * as uiActions from '../../store/actions/uiActions/actions';

import AdjustVideo from '../../components/AdjustVideo/AdjustVideo';
import * as URLConstants from '../../utils/Constants';
import axios from '../../utils/Axios';

function VideoPreview(props) {
    const [yPosition, setYPosition] = useState(NaN);
    const videoClicked = useSelector(state => state.videoReducer.videoClicked);
    const chosenVideo = useSelector(state => state.videoReducer.chosenVideo);
    const can_edit = useSelector(state => state.authenticationReducer.is_admin || state.authenticationReducer.is_staff);
    const showVideoOptions = useSelector(state => state.uiReducer.showVideoOptions);
    const showTrashOptions = useSelector(state => state.uiReducer.showTrashOptions);

    const dispatch = useDispatch();

    useEffect(() => {
        if(isNaN(yPosition)) {
            return;
        } 

        const params = new FormData();
        params.append('id_', props.id);
        params.append('xPosition', 0);
        params.append('yPosition', -yPosition);

        axios.post(URLConstants.POST_ADJUST_X_Y, params).catch(error => {
            console.error(error);
        });
        // eslint-disable-next-line
    }, [yPosition]);

    function onChose(evt) {
        dispatch(actions.choseVideoAction({videoClicked: true, ...props}));
    }

    if(chosenVideo && videoClicked) {
        return <Redirect to={`/video-page/${chosenVideo.id}`} />;
    }

    const activeYPosition = isNaN(yPosition) ? props.yPosition : yPosition;
    const adjustVideoComponents = can_edit ? <AdjustVideo yPosition={activeYPosition} setYPosition={setYPosition} /> : null;
    let style = {
        objectPosition: `${props.xPosition}px ${props.yPosition}px`
    };

    if(!isNaN(yPosition)) {
        style = {
            objectPosition: `0px -${yPosition}px`
        };
    }

    return (
        <React.Fragment>
            <div className="video-previews__item">
                <div 
                    className="video-previews__item__preview-static" 
                    onClick={props.isTrash ? null : onChose}>
                        <img 
                            className="video-previews__item__thumbnail" 
                            src={props.thumbnail} 
                            style={style}
                            alt="thumbnail" />
                        {adjustVideoComponents}
                </div>
                <article className="video-preview">
                    <article className="video-preview__content">
                        <p className="video-preview__content__title">
                            {props.description}
                        </p>
                        <p className="video-preview__content__views">
                            {props.views} views
                        </p>
                    </article>
                    <div 
                        className='video-preview__watch-options'
                        onClick={() => {
                            const options = props.isTrash ? showTrashOptions : showVideoOptions;
                            const newOptions = options.map(option => {
                                if(option.id === props.id) {
                                    option.show = !option.show;
                                }

                                return option;
                            });

                            const isNotInArray = options.filter(option => option.id === props.id).length === 0;

                            if(isNotInArray) {
                                newOptions.push({id: props.id, show: true});
                            }

                            if(props.isTrash) {
                                dispatch(uiActions.showTrashOptionsEvent(newOptions));
                            }
                            else {
                                dispatch(uiActions.showVideoOptionsEvent(newOptions));
                            }
                            
                        }}>
                        {WATCH_OPTIONS}
                    </div>
                </article>
                {props.optionsComponent}
            </div>
        </React.Fragment>
    );
}

export default VideoPreview; 