import React from 'react';
import {useSelector} from 'react-redux';

import {DELETE, ADD_TO_WATCH_LIST, SOCIAL_SHARE} from '../../utils/SVGConstants';

import * as URLConstants from '../../utils/Constants';
import axios from '../../utils/Axios';
import classnames from 'classnames';

function VideoOptions(props) {
    const showVideoOptions = useSelector(state => state.uiReducer.showVideoOptions);
    const username = useSelector(state => state.authenticationReducer.username);
    const is_admin = useSelector(state => state.authenticationReducer.is_admin);
    const is_staff = useSelector(state => state.authenticationReducer.is_staff);

    function onTrashVideo(evt) {
        const params = new FormData();

        params.append('id', props.id);

        axios.post(URLConstants.POST_TRASH_VIDEO, params)
        .then(response => {
            if(props.onVideoDelete) {
                props.onVideoDelete();
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    let optionsClasses = classnames('video-previews__options');
    let optionListClasses = classnames('video-previews__option-list');
    let isVisible = showVideoOptions.filter(option => option.show && option.id === props.id).length > 0;

    if(isVisible) {
        optionsClasses = classnames('video-previews__options', 'video-previews__options--show');
        optionListClasses = classnames('video-previews__option-list', 'video-previews__option-list--show');
    }

    let deleteOption = null;

    if(username === props.username || is_admin || is_staff) {
        deleteOption = (
            <li className="video-previews__option-list-item" onClick={onTrashVideo}>
                    {DELETE} <span className="video-previews__option-list-item-text">Delete Video</span>
            </li>
        );
    }

    return (
        <div className={optionsClasses} >
            <ul className={optionListClasses}>
                <li className="video-previews__option-list-item">
                    {SOCIAL_SHARE} <span className="video-previews__option-list-item-text">Share Video</span>
                </li>
                <li className="video-previews__option-list-item">
                    {ADD_TO_WATCH_LIST} <span className="video-previews__option-list-item-text">Save to Watch Later</span>
                </li>
                {deleteOption}
            </ul>
        </div>
    );
}

export default VideoOptions;