import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import InactiveMobileSearch from '../Search/InactiveMobileSearch';
import ActiveMobileSearch from '../Search/ActiveMobileSearch';

import * as URLActions from '../../utils/Constants';
import * as authActions from '../../store/actions/authenticationActions/actions';

import axios from '../../utils/Axios';

function NavigationMobile(props) {
    const showSearchMobile = useSelector(state => state.uiReducer.showSearchMobile);
    const dispatch = useDispatch();

    function logout() {
        axios.post(URLActions.LOGOUT_URL).then(response => {
            dispatch(authActions.logoutEventAction());
        }).catch(error => {
            console.error(error);
            dispatch(authActions.logoutEventAction());
        });
    }

    let view = showSearchMobile ? <ActiveMobileSearch /> : <InactiveMobileSearch logout={logout}/>;

    return (
        <React.Fragment>
            { view }
        </React.Fragment>
    );
}

export default NavigationMobile;