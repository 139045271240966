import React from 'react';
import { useSelector } from 'react-redux';
import {Redirect} from 'react-router-dom';

const withAuthRedirect = Component => props => {
    const isLoggedIn = useSelector(state => state.authenticationReducer.isLoggedIn || Boolean(localStorage.getItem('loggedIn')));

    if (!isLoggedIn) {
        return <Redirect to="/login-page" />;
    }

    return <Component {...props} />;
}

export default withAuthRedirect;