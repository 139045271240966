import React, { useState, useEffect } from 'react';
import {Redirect} from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import Video from '../../components/Video/Video';
import CommentsSection from '../../components/CommentsSection/CommentsSection';
import VideoOptions from '../../components/VideoOptions/VideoOptions';
import VideoPreview from '../../components/VideoPreview/VideoPreview';

import * as actions from '../../store/actions/videoActions/actions';
import * as uiActions from '../../store/actions/uiActions/actions';
import * as URLConstants from '../../utils/Constants';
import axios from '../../utils/Axios';

import withAuthRedirect from '../../hoc/withAuthRedirect';

function VideoPage(props) {
    const [videos, setVideos] = useState([]);
    const [videoWasDeleted, setVideoWasDeleted] = useState(false);
    const [showVideoDescription, setShowVideoDescription] = useState(false);
    const commentClicked = useSelector(state => state.uiReducer.commentClicked);
    const chosenVideo = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const onVideoDelete = () => setVideoWasDeleted(true);
    const getContent = () => {
        axios.get(URLConstants.GET_CONTENT_LIST_URL).then(response => {
            setVideos(response.data.videos.map(video => {
                const component = <VideoOptions {...video} onVideoDelete={onVideoDelete}/>;

                return (
                    <VideoPreview 
                        key={video.id} 
                        {...video} 
                        optionsComponent={component}
                        onVideoDelete={onVideoDelete}/>
                );
            }));
        }).catch(error => {
            console.log(error);
        });
    };

    function addView() {
        const params = new URLSearchParams();

        params.append('id', props.match.params.id);

        axios.post(URLConstants.POST_INCREASE_VIEWS, params).then(response => {
            if(response.data.code === 1) {
                dispatch(actions.videoIncreaseViewsAction());
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        addView();
        // eslint-disable-next-line
    }, []);

    //props.match.params.id
    useEffect(() => {
        if(!chosenVideo.videoClicked) {
            return;
        }

        dispatch(actions.didChooseVideo(false));

        const desktopMql = window.matchMedia('(min-width: 40rem)');

        const handleMQChangeToMobile = (evt) => {
            setShowVideoDescription(evt.matches);
        };

        desktopMql.addEventListener('change', handleMQChangeToMobile);

        return () => { 
            dispatch(actions.didChooseVideo(false));

            desktopMql.removeEventListener('change', handleMQChangeToMobile);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(actions.didChooseVideo(false));
        getContent();
        // eslint-disable-next-line
    }, [props.match.params.id, chosenVideo.id]);

    useEffect(() => {
        dispatch(uiActions.showVideoOptionsEvent([]));
        dispatch(uiActions.showTrashOptionsEvent([]));
        getContent();
        // eslint-disable-next-line
    }, [videoWasDeleted]);

    useEffect(() => {
        axios.get(URLConstants.GET_VIDEO_URL.replace("ID", props.match.params.id))
        .then(response => {
            dispatch(actions.choseVideoAction({videoClicked: false, ...response.data}));
        }).catch(error => {
            console.error(error);
        });
        // eslint-disable-next-line
    }, [props.match.params.id, chosenVideo.hasThumbsUp, chosenVideo.hasThumbsDown]);

    const show = showVideoDescription || !commentClicked;

    if(props.match.params.id && chosenVideo && chosenVideo.id && parseInt(props.match.params.id, 10) !== chosenVideo.id) {
        return <Redirect to={`/video-page/${chosenVideo.id}`}/>;
    }

    return (
        <React.Fragment>
            <section className="video-page">
                <Video showDescription={show} {...chosenVideo}/>
                <CommentsSection />
                <section className="video-previews">
                {show ? videos : null}
                </section>
            </section>
        </React.Fragment>
    );
}

export default withAuthRedirect(VideoPage);