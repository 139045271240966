import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import ToggleButton from '../ToggleButton/ToggleButton';
import SearchMobile from '../Search/SearchMobile';
import Logout from '../Button/Button';
import famtubeicon from '../../imgs/famtube.png';

import classnames from 'classnames';

const InactiveMobileSearch = props => {
    const classes = classnames("login", "login--mright");
    const isLoggedIn = useSelector(state => state.authenticationReducer.isLoggedIn);

    return (
        <nav className="nav-mobile">
            <ToggleButton />
            <div className="nav-mobile__left-panel-access">
                <Link className="anchor-img-container" to="/">
                    <img src={famtubeicon} alt="FamTube Icon"/>
                </Link>
                <SearchMobile />
            </div>
            {isLoggedIn ? 
                <Logout
                containerClassNames={classes}
                buttonClassNames="login__button"
                text="Logout"
                clickHandler={props.logout} />
            : null}
        </nav>
    );
}

export default InactiveMobileSearch;