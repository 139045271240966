import {updateObject} from '../../../utils/CommonUtils';
import * as actionTypes from '../../actions/videoActions/actionTypes';

const initialState = {
    id: null,
    type: null,
    video: null,
    alt_video: null,
    thumbnail: null,
    name: null,
    description: null,
    mimetype: null,
    share_url: null,
    views: null,
    likes: null,
    dislikes: null,
    downloads: null,
    hasThumbsUp: false,
    hasThumbsDown: false,
    videoClicked: false,
    xPosition: 0,
    yPosition: 0
}

const currentVideo = (state, action) => {
    return updateObject(state, {...action});
}

const sentiment = (state, action) => {
    return updateObject(state, {videoClicked: false, ...action});
}

const increaseView = (state, action) => {
    const views = state.views + 1;

    return updateObject(state, { views: views});
}

const videoChosen = (state, action) => {
    return updateObject(state, {videoChosen: action.videoClicked});
}

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case actionTypes.CURRENT_VIDEO_EVENT:
            return currentVideo(state, action);
        case actionTypes.THUMBS_UP_EVENT:
            return sentiment(state, action);
        case actionTypes.THUMBS_DOWN_EVENT:
            return sentiment(state, action);
        case actionTypes.VIEWS_INCREASE_EVENT:
            return increaseView(state, action);
        case actionTypes.VIDEO_CHOSEN_EVENT:
            return videoChosen(state, action);
        default:
            return state;
    }
}