export const UI_ACTION_BACKSTOP_SHOW = "uiActionBackstopShowEvent";
export const UI_ACTION_BACKSTOP_HIDE = "uiActionBackstopHideEvent";

export const SHOW_VIDEO_OPTIONS = "uiActionShowVideoOptionsEvent";
export const SHOW_TRASH_OPTIONS = "uiActionShowTrashOptionsEvent";

export const UI_ACTION_NAVBAR_SHOW = "uiActionNavBarShowEvent";
export const UI_ACTION_NAVBAR_HIDE = "uiActionNavBarHideEvent";

export const ONLOAD_OVERLAY_EVENT = "uiActionShowOverlayEvent";
export const UNLOAD_OVERLAY_EVENT = "uiActionHideOverlayEvent";

export const ONLOAD_ALERT_OVERLAY_EVENT = "uiActionShowAlertOverlayEvent";
export const UNLOAD_ALERT_OVERLAY_EVENT = "uiActionHideAlertOverlayEvent";

export const CANCELATION_EVENT = "uiActionCancelationEvent";

export const SHOW_SIDE_PANEL = "uiActionShowSidePanelEvent";
export const HIDE_SIDE_PANEL = "uiActionHideSidePanelEvent";

export const SHOW_SEARCH_MOBILE = "uiActionShowSearchMobileEvent";
export const HIDE_SEARCH_MOBILE = "uiActionHideSearchMobileEvent";

export const SHOW_SEARCH_DESKTOP = "uiActionShowSearchDesktopEvent";
export const HIDE_SEARCH_DESKTOP = "uiActionHideSearchDesktopEvent";

export const SHOW_REPORT = "uiActionShowReportEvent";
export const HIDE_REPORT = "uiActionHideReportEvent";

export const SHOW_UPLOAD = "uiActionShowUploadEvent";
export const HIDE_UPLOAD = "uiActionHideUploadEvent";

export const SHOW_FOOTER = "uiActionShowFooterEvent";
export const HIDE_FOOTER = "uiActionHideFooterEvent";

export const COMMENT_CLICKED = "uiActionCommentClickedEvent";
export const WAS_UPLOADED = "uiActionWasUploadedEvent";

export const DID_CHOOSE_PENDING_TASK = "didChoosePendingTaskEvent";
export const DID_CHOOSE_TRASH = "didChooseTrashEvent";