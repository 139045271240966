import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import anonymous_user from '../../imgs/anonymous_person.jpg';
import Comment from '../Comment/Comment';
import CommentHeader from '../CommentHeader/CommentHeader';
import CommentListHeader from '../CommentListHeader/CommentListHeader';

import * as actions from '../../store/actions/uiActions/actions';
import * as URLConstants from '../../utils/Constants';
import axios from '../../utils/Axios';

import classnames from 'classnames';

function CommentsSection(props) {
    const [commentCount, setCommentCount] = useState(0);
    const [comments, setComments] = useState(false);
    const commentClicked = useSelector(state => state.uiReducer.commentClicked);
    const chosenVideo = useSelector(state => { 
        return {...state.videoReducer};
    });
    const dispatch = useDispatch();

    function getComments() {
        const url = URLConstants.GET_CONTENT_FOR_TYPE_URL.replace('TYPE', 'video').replace('TYPE_ID', chosenVideo.id);

        axios.get(url).then(response => {
            const cmnts = response.data.comments.map((value, index) => {
                return <Comment key={index} username={value.username} comment={value.comment}/>;
            });

            setComments(cmnts);
            setCommentCount(cmnts.length);
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        if(!chosenVideo || !chosenVideo.id) {

            return;
        }

        getComments();
        // eslint-disable-next-line
    }, [chosenVideo.id]);

    const onCommentEntered = () => {
        getComments();
    }

    const onCommentClicked = () => {
        dispatch(actions.commentClicked());
    }

    let commentSection = <CommentHeader onCommentClicked={onCommentClicked} commentCount={commentCount} />;
    let classNames = classnames('user-comment-block');
    const showSection = <CommentListHeader 
        onCommentClicked={onCommentClicked} 
        photo={anonymous_user} 
        comments={comments}
        onCommentEntered={onCommentEntered}/>;
    
    if (commentClicked) {
        commentSection = <CommentListHeader 
            onCommentClicked={onCommentClicked} 
            photo={anonymous_user} 
            comments={comments}
            onCommentEntered={onCommentEntered}/>;
        classNames = classnames('user-comment-block--show')
    }

    return (
        <React.Fragment>
            <section className={classNames}>
            {commentSection}
            </section>
            <section className='user-comment-block-desktop'>
            {showSection}
            </section>
        </React.Fragment>
    );
}

export default CommentsSection;