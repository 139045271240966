import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Upload from '../../components/FileUpload/FileUpload';
import NavigationMobile from '../../components/Navigation/NavigationMobile';
import NavigationOther from '../../components/Navigation/NavigationOther';
import NotificationPopup from '../../components/NoticationPopup/NotificationPop';
import AlertNotification from '../../components/AlertNotification/AlertNotification';
import SideBar from '../../components/SideBar/SideBar';
import Footer from '../../components/Footer/Footer';

import withAuthentication from '../../hoc/withAuthentication';
import * as uiActions from '../../store/actions/uiActions/actions';

import classnames from 'classnames';

function Layout(props) {
    const showNavBar = useSelector(state => state.uiReducer.showNavBar);
    const showSidePanel = useSelector(state => state.uiReducer.showSidePanel);
    const showBackDrop = useSelector(state => state.uiReducer.showBackDrop);
    const uiBlocked = useSelector(state => state.uiReducer.uiBlocked);
    const showFooter = useSelector(state => state.uiReducer.showFooter);

    const dispatch = useDispatch();
    
    const sidePanelClass = !showSidePanel ? classnames("side-panel") : classnames("side-panel", "side-panel--show");
    const backdropName = showSidePanel || showBackDrop ? classnames("backdrop", "backdrop--show") : classnames("backdrop");
    const mainClasses = showNavBar ? null : classnames("no-margins-paddings");
    let navBar = (
        <React.Fragment>
            <NavigationMobile />
            <NavigationOther />
        </React.Fragment>
    );

    if(!showNavBar) {
        navBar = null;
    }

    return (
        <React.Fragment>
            <div className={backdropName} onClick={() => {
                if(!uiBlocked) {
                    dispatch(uiActions.sidePanelHideEvent());
                }
            }} />
            {navBar}
            <Upload/>
            <NotificationPopup />
            <AlertNotification />
            <SideBar sidePanelClass={sidePanelClass} />
            <main className={mainClasses}>
                {props.children}
                {showFooter ? <Footer /> : null}
            </main>
        </React.Fragment>
    );
}

export default withAuthentication(Layout);