import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {Redirect} from 'react-router-dom';

import Login from '../../components/Button/Button';
import * as uiActions from '../../store/actions/uiActions/actions';
import * as authActions from '../../store/actions/authenticationActions/actions';

import * as URLConstants from '../../utils/Constants';
import {EVENT_STATE_SUCCESS, EVENT_STATE_ERROR} from '../../utils/Constants';

import axios from '../../utils/Axios';

import {isBlank} from '../../utils/CommonUtils';

import classnames from 'classnames';

import famtubeicon from '../../imgs/famtube.png';

function LoginPage(props) {
    const buttonStyles = classnames("login__button", "login__button--full-width");
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.authenticationReducer.isLoggedIn || Boolean(localStorage.getItem('loggedIn')));

    useEffect(() => {
        dispatch(uiActions.navBarHideEvent());
        dispatch(uiActions.sidePanelHideEvent());
        dispatch(uiActions.hideFooterEvent());
        // eslint-disable-next-line
    }, []);

    const onSubmit = evt => {
        evt.preventDefault();

        if(isBlank(username) || isBlank(password)) {
            dispatch(uiActions.alertLoadOverlayEventAction("Invalid credentials", EVENT_STATE_ERROR));

            return;
        }

        const params = new URLSearchParams();

        params.append('username', username);
        params.append('password', password);

        dispatch(uiActions.genericLoadOverlayEventAction("Logging in...", EVENT_STATE_SUCCESS));

        axios.post(URLConstants.LOGIN_URL, params)
        .then(response => {
            dispatch(uiActions.genericUnloadOverlayEventAction());
            dispatch(uiActions.navBarShowEvent());
            dispatch(authActions.loginEventAction(response.data.username, response.data.is_admin, response.data.is_staff));
            dispatch(uiActions.showFooterEvent());
        }) 
        .catch(error => {
            dispatch(uiActions.genericUnloadOverlayEventAction());
            dispatch(uiActions.alertLoadOverlayEventAction("Login failed.. try again", EVENT_STATE_ERROR));
            dispatch(authActions.logoutEventAction());
            dispatch(uiActions.hideFooterEvent());
        });
    };

    if(isLoggedIn) {
        return <Redirect to="/"/>;
    }

    return (
        <React.Fragment>
            <article className="login-form-wrapper">
                <form action="" className="login-form" onSubmit={onSubmit}>
                    <div className="login-form__sign-in-header">
                        <div className="div-img-container">
                            <img src={famtubeicon} alt="FamTube Icon"/>
                        </div>
                        <h1>Sign in</h1>
                    </div>
                    <input 
                        type="text" 
                        name="username" 
                        className="login-form__username" 
                        placeholder="Enter username" 
                        onChange={(evt) => setUsername(evt.target.value)}/>
                    <input 
                        type="password" 
                        name="password" 
                        className="login-form__password" 
                        placeholder="Enter password" 
                        onChange={(evt) => setPassword(evt.target.value)}/>
                    <Login 
                        containerClassNames="login" 
                        buttonClassNames={buttonStyles} 
                        text="Login"
                        clickHandler={() => {
                            return;
                        }} />
                </form>
            </article>
        </React.Fragment>
    );
}

export default LoginPage;