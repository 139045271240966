import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as authActions from '../store/actions/authenticationActions/actions';

const withAuthentication = Component => props => {
    const isLoggedIn = useSelector(state => state.authenticationReducer.isLoggedIn);
    const loginSaved = Boolean(localStorage.getItem('loggedIn'));

    const dispatch = useDispatch();

    useEffect(() => {
        if(!isLoggedIn && loginSaved) {
            dispatch(authActions.loginEventAction(localStorage.getItem('username'), localStorage.getItem('is_admin'), localStorage.getItem('is_staff')));
        }
        // eslint-disable-next-line
    }, []);

    return <Component {...props} />;
}

export default withAuthentication;