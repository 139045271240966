import React, {useState} from 'react';
import ChosenThumbnail from '../ChosenThumbnail/ChosenThumbnail';

import AdjustVideo from '../AdjustVideo/AdjustVideo';
import classnames from 'classnames';

function Thumbnail({index, thumbnail, onChosen, isChosen}) {
    const [yPosition, setYPosition] = useState(0);
    const style = {objectPosition: `0px -${yPosition}px`};

    return (
        <div
            className="video-add-grid__thumbnail-option"
            onClick={(evt) => {
                onChosen(index, <ChosenThumbnail thumbnail={thumbnail} xPosition={0} yPosition={-yPosition}/>, 0, -yPosition);
            }}>
            {isChosen ? <div className={classnames("video-add-grid--chosen")}>Chosen</div> : null}
            <img className="video-add-grid__thumbnail" src={thumbnail} alt="thumbnail option" style={style}/>
            <AdjustVideo yPosition={yPosition} setYPosition={setYPosition} />
        </div>
    );
}

export default Thumbnail;