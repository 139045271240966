import * as uiActionTypes from './actionTypes';

export const sidePanelShowEvent = () => {
    return {
        type: uiActionTypes.SHOW_SIDE_PANEL
    }
};

export const sidePanelHideEvent = () => {
    return {
        type: uiActionTypes.HIDE_SIDE_PANEL
    }
};

export const didChoosePendingTaskEvent = () => {
    return {
        type: uiActionTypes.DID_CHOOSE_PENDING_TASK
    }
};

export const didChooseTrashEvent = () => {
    return {
        type: uiActionTypes.DID_CHOOSE_TRASH
    }
};

export const searchMobileShowEvent = () => {
    return {
        type: uiActionTypes.SHOW_SEARCH_MOBILE
    }
};

export const searchMobileHideEvent = () => {
    return {
        type: uiActionTypes.HIDE_SEARCH_MOBILE
    }
};

export const searchDesktopShowEvent = () => {
    return {
        type: uiActionTypes.SHOW_SEARCH_DESKTOP
    }
};

export const searchDesktopHideEvent = () => {
    return {
        type: uiActionTypes.HIDE_SEARCH_DESKTOP
    }
};

export const showReportEvent = () => {
    return {
        type: uiActionTypes.SHOW_REPORT
    }
};

export const hideReportEvent = () => {
    return {
        type: uiActionTypes.HIDE_REPORT
    }
};

export const showFooterEvent = () => {
    return {
        type: uiActionTypes.SHOW_FOOTER
    }
};

export const hideFooterEvent = () => {
    return {
        type: uiActionTypes.HIDE_FOOTER
    }
};

export const showUploadEvent = () => {
    return {
        type: uiActionTypes.SHOW_UPLOAD
    }
};

export const showVideoOptionsEvent = (showOptions) => {
    return {
        type: uiActionTypes.SHOW_VIDEO_OPTIONS,
        showVideoOptions: showOptions
    }
};

export const showTrashOptionsEvent = (showOptions) => {
    return {
        type: uiActionTypes.SHOW_TRASH_OPTIONS,
        showTrashOptions: showOptions
    }
};

export const hideUploadEvent = () => {
    return {
        type: uiActionTypes.HIDE_UPLOAD
    }
};

export const backStopShowEvent = () => {
    return {
        type: uiActionTypes.UI_ACTION_BACKSTOP_SHOW
    }
};

export const backStopHideEvent = () => {
    return {
        type: uiActionTypes.UI_ACTION_BACKSTOP_HIDE
    }
};

export const wasUploaded = didUpload => {
    return {
        type: uiActionTypes.WAS_UPLOADED,
        wasUploaded: didUpload
    }
}

export const navBarShowEvent = () => {
    return {
        type: uiActionTypes.UI_ACTION_NAVBAR_SHOW
    }
};

export const navBarHideEvent = () => {
    return {
        type: uiActionTypes.UI_ACTION_NAVBAR_HIDE
    }
};

export const genericLoadOverlayEventAction = (message, eventState) => {
    return {
        type: uiActionTypes.ONLOAD_OVERLAY_EVENT,
        message: message,
        eventState: eventState
    };
};

export const genericUnloadOverlayEventAction = () => {
    return {
        type: uiActionTypes.UNLOAD_OVERLAY_EVENT
    };
};

export const alertLoadOverlayEventAction = (message, eventState) => {
    return {
        type: uiActionTypes.ONLOAD_ALERT_OVERLAY_EVENT,
        message: message,
        eventState: eventState
    };
};

export const alertUnloadOverlayEventAction = () => {
    return {
        type: uiActionTypes.UNLOAD_ALERT_OVERLAY_EVENT
    };
};

export const commentClicked = () => {
    return {
        type: uiActionTypes.COMMENT_CLICKED
    }
}