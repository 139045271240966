import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import VideoMetaData from '../VideoMetaData/VideoMetaData';
import { ReactVideo } from "reactjs-media";

import * as actions from '../../store/actions/videoActions/actions';
import * as URLConstants from '../../utils/Constants';
import axios from '../../utils/Axios';
import { isBlank } from '../../utils/CommonUtils';

function ShareVideo(props) {
    const [supportsMimeType, setSupportsMimeType] = useState(true);
    const [hasError, setHasError] = useState(false);
    const emptyVideoRef = useRef(null);
    const { shareUrl } = useParams();
    const dispatch = useDispatch();
    const video = useSelector(state => state.videoReducer);
    let content = <VideoMetaData {...video} readonly/>;

    useEffect(() => {
        setHasError(false);

        axios.get(URLConstants.GET_SHARE_URL + shareUrl).then(response => {
            dispatch(actions.choseVideoAction({ ...response.data.video }));
        })
        .catch(error => {
            setHasError(true);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const videoDiv = document.querySelector('.poster > div');

        if(videoDiv) {
            videoDiv.style.backgroundImage = `url(${video.thumbnail})`;
            videoDiv.style.backgroundPosition = `${video.xPosition}px ${video.yPosition}px`;
        }

        if (emptyVideoRef.current && video) {
            const canPlay = !isBlank(emptyVideoRef.current.canPlayType(video.mimetype));

            setSupportsMimeType(canPlay);
        }
        // eslint-disable-next-line
    }, [video]);

    let videoContent = (
        <React.Fragment>
            <div style={{ display: 'none' }}>
                <video ref={emptyVideoRef}></video>
            </div>
            <div className="video-page__container">
                {video.share_url ?
                    <ReactVideo
                        key={video.id}
                        src={supportsMimeType ? video.video : video.alt_video}
                        type={supportsMimeType ? video.mimetype : "video/mp4"}
                        poster={video.thumbnail}
                        className='video-page__video' />
                : null}
            </div>
            {content}
            <div className="horizontal-splitter" />
        </React.Fragment>
    );

    if(hasError) {
        videoContent = (
            <h2 className="page-not-found-robot">
                <span>Page Not Found /404</span>
                <i className="fas fa-robot"></i>
            </h2>
        );
    }

    return (
        <React.Fragment>
            <section className="video-page video-page-share">
                {videoContent}
            </section>
        </React.Fragment>
    );
}

export default ShareVideo;