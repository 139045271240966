import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as uiActions from '../../store/actions/uiActions/actions';

import * as URLConstants from '../../utils/Constants';
import * as SVG_CONST from '../../utils/SVGConstants';

import { EVENT_STATE_ERROR, EVENT_STATE_SUCCESS } from '../../utils/Constants';
import { WhiteThumbsUp, WhiteThumbsDown, BlackThumbsUp, BlackThumbsDown } from '../../utils/SVGConstants';
import { Download, Report } from '../../utils/SVGConstants';

import ReportInput from '../../components/TextBoxInput/TextBoxInput';

import { isBlank } from '../../utils/CommonUtils';

import axios from '../../utils/Axios';
import classnames from 'classnames';
import download from 'downloadjs';

function VideoMetaData(props) {
    const [willShare, setWillShare] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const hasThumbsUp = useSelector(state => state.videoReducer.hasThumbsUp);
    const hasThumbsDown = useSelector(state => state.videoReducer.hasThumbsDown);
    const likes = useSelector(state => state.videoReducer.likes);
    const dislikes = useSelector(state => state.videoReducer.dislikes);
    const showReport = useSelector(state => state.uiReducer.showReport);
    const dispatch = useDispatch();
    const whatsAppRef = useRef(null);
    const smsRef = useRef(null);

    useEffect(() => {
        const onLoadREMSize = getREMSize();

        if(onLoadREMSize >= 40) {
            setIsDesktop(true);
        }

        const desktopMql = window.matchMedia('(min-width: 40rem)');

        const handleMQChangeToMobile = (evt) => {
            setIsDesktop(desktopMql.matches);
        };

        desktopMql.addEventListener('change', handleMQChangeToMobile);

        return () => { 
            desktopMql.removeEventListener('change', handleMQChangeToMobile);
        };
        // eslint-disable-next-line
    }, []);

    function getREMSize() {
        const computedStyle = window.getComputedStyle(document.documentElement);
        const fontSize = computedStyle.fontSize;

        return document.body.clientWidth / parseInt(fontSize);
    }

    function onThumbsUp() {
        const params = new URLSearchParams();

        params.append('type_id', props.id);
        params.append('type', 'video');

        axios.post(URLConstants.POST_LIKE_URL, params)
            .then(response => {
                if (response.data.code === 1) {
                    dispatch(actions.videoLikeAction());
                }
            }).catch(error => {
                console.error(error);
            });
    }

    function onThumbsDown() {
        const params = new URLSearchParams();

        params.append('type_id', props.id);
        params.append('type', 'video');

        axios.post(URLConstants.POST_DISLIKE_URL, params)
            .then(response => {
                if (response.data.code === 1) {
                    dispatch(actions.videoDisLikeAction());
                }
            }).catch(error => {
                console.error(error);
            });
    }

    function onDownload() {
        const params = new URLSearchParams();

        params.append('id', props.id);

        axios.post(URLConstants.POST_DOWNLOAD_URL, params, {
            responseType: 'arraybuffer'
        })
            .then(response => {
                download(new Blob([response.data]),
                    `video_${new Date().toLocaleString()}.${response.headers['content-disposition'].split('.')[1]}`,
                    response.headers['content-type']);
            }).catch(error => {
                console.error(error);
            });
    }

    function onReport(comment) {
        if (isBlank(comment)) {
            return;
        }

        const params = new URLSearchParams();

        params.append('type_id', props.id);
        params.append('type', 'video');
        params.append('comment', comment);

        axios.post(URLConstants.POST_REPORT_URL, params).then(response => {
            if (response.data.code === 1) {
                dispatch(uiActions.alertLoadOverlayEventAction('Successfully reported video', EVENT_STATE_SUCCESS));
            }
            else {
                dispatch(uiActions.alertLoadOverlayEventAction('Comment not saved.. error occured', EVENT_STATE_ERROR));
            }
        }).catch(error => {
            console.error(error);
        }).finally(response => {
            dispatch(uiActions.hideReportEvent());
        });
    }

    const thumbsUpSVG = hasThumbsUp ? <BlackThumbsUp /> : <WhiteThumbsUp onClick={onThumbsUp} />;
    const thumbsDownSVG = hasThumbsDown ? <BlackThumbsDown /> : <WhiteThumbsDown onClick={onThumbsDown} />;
    const tDownClasses = hasThumbsDown ? classnames('thumbs-down thumbs-down--black') : classnames('thumbs-down');
    const shareDescription = `Check out: video "${props.description}" on famtube.us ${props.share_url}`;
    let whatsappUrl = `whatsapp://send?text=${shareDescription}`;
    let smsUrl = `sms:&body=${shareDescription}`;

    if((/Mobi|Android/i.test(navigator.userAgent))){
        smsUrl = `sms:?body=${shareDescription}`;
    }

    if(isDesktop) {
        whatsappUrl = `https://wa.me/?text=${shareDescription}`;
    }

    let actions = classnames('video-page__content__actions');

    if(props.readonly) {
        actions = classnames('video-page__content__actions', 'video-page__content__actions_readonly');
    }

    let phoneIconsClass = classnames('phoneIcons');
    let shareClass = classnames('share');
    let smsClass = classnames('sms');
    let whatsAppClass = classnames('whatsApp');

    if(willShare) {
        phoneIconsClass = classnames('phoneIcons', 'phoneIcons--active');
        shareClass = classnames('share', 'share--active');
        smsClass = classnames('sms', 'sms--active');
        whatsAppClass = classnames('whatsApp', 'whatsApp--active');
    }

    return (
        <React.Fragment>
            <a ref={whatsAppRef} style={{display: 'none'}} href={whatsappUrl} />
            <a ref={smsRef} style={{display: 'none'}} href={smsUrl} />
            <ReportInput
                title="Report"
                buttonTxt="Submit Report"
                show={showReport}
                onClick={onReport} />
            <section className="video-page__content">
                <p className="video-page__content__title">
                    {props.description}
                </p>
                <p className="video-page__content__views">
                    {props.views} views
            </p>
                <div className={actions}>
                    <div className="thumbs-up">
                        {thumbsUpSVG}
                        <p className="video-action__content">
                            {likes}
                        </p>
                    </div>
                    {willShare && !isDesktop ? 
                        null
                    : (
                        <div className={tDownClasses}>
                            {thumbsDownSVG}
                            <p className="video-action__content">
                                {dislikes}
                            </p>
                        </div>
                      )
                    }
                    <div className={shareClass} onClick={() => {
                        if (navigator.share) {
                            navigator.share({
                                title: `Famtube US Video`,
                                text: `Check out: video "${props.description}" on famtube.us`,
                                url: props.share_url,
                            })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                        }
                        else {
                            setWillShare(!willShare);
                        }
                    }}
                    >
                        {SVG_CONST.SHARE}
                        <p className="video-action__content">
                            Share
                        </p>
                        <div className={phoneIconsClass} onClick={evt => console.log('got click...')}>
                            <span className={smsClass} onClick={evt => {
                                evt.stopPropagation();
                                smsRef.current.click();
                            }}></span>
                            <span className={whatsAppClass} onClick={evt => {
                                evt.stopPropagation();
                                whatsAppRef.current.click();
                            }}></span>
                        </div>
                    </div>
                    {willShare && !isDesktop ? null
                        :
                        (<div className="download">
                            <Download onClick={onDownload} />
                            <p className="video-action__content">
                                Download
                            </p>
                        </div>)
                    }
                    
                    <div className="report">
                        <Report onClick={() => {
                            dispatch(uiActions.showReportEvent());
                        }} />
                        <p className="video-action__content">Report</p>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default VideoMetaData;