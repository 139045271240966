import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import * as SVGConstants from '../../utils/SVGConstants';
import * as uiActions from '../../store/actions/uiActions/actions';

import classnames from 'classnames';

export default function ToggleButton(props) {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.authenticationReducer.isLoggedIn);

    function onToggle(evt) {
        dispatch(uiActions.sidePanelShowEvent());
    }

    let toggleClass = classnames('toggle');

    if(!isLoggedIn) {
        toggleClass = classnames('toggle', 'toggle--disable');
    }

    return (
        <div className={toggleClass} onClick={onToggle}>
            {SVGConstants.TOGGLE_BUTTON}
        </div>
    );
}