import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {useSelector} from 'react-redux';

import * as actions from '../../store/actions/videoActions/actions';
import * as uiActions from '../../store/actions/uiActions/actions';
import * as URLConstants from '../../utils/Constants';
import {DEFAULT_VIDEO} from '../../utils/Constants';

import VideoOptions from '../../components/VideoOptions/VideoOptions';
import VideoPreview from '../../components/VideoPreview/VideoPreview';
import axios from '../../utils/Axios';

import withAuthRedirect from '../../hoc/withAuthRedirect';

function HomePage(props) {
    const [previews, setPreviews] = useState([]);
    const [videoWasDeleted, setVideoWasDeleted] = useState(false);
    const chosenVideo = useSelector(state => state.videoReducer);
    const dispatch = useDispatch();
    const onVideoDelete = () => setVideoWasDeleted(true);
    const getContent = () => {
        axios.get(URLConstants.GET_CONTENT_URL).then(response => {
            setPreviews(response.data.videos.map(video => {
                const component = <VideoOptions {...video} onVideoDelete={onVideoDelete}/>;

                return (
                    <VideoPreview 
                        key={video.id} 
                        {...video} 
                        optionsComponent={component} 
                        onVideoDelete={onVideoDelete}/>
                );
            }));
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        dispatch(actions.choseVideoAction(DEFAULT_VIDEO));
        getContent();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!videoWasDeleted) {
            return;
        }

        dispatch(uiActions.showVideoOptionsEvent([]));
        dispatch(uiActions.showTrashOptionsEvent([]));
        getContent();
        // eslint-disable-next-line
    }, [videoWasDeleted]);

    if(chosenVideo.videoClicked) {
        return <Redirect to={`/video-page/${chosenVideo.id}`}/>;
    }

    return (
        <React.Fragment>
            <section className="home-page">
                {previews}
            </section>
        </React.Fragment>
    );
}

export default withAuthRedirect(HomePage);