import React from 'react';

function Button({containerClassNames, buttonClassNames, text, clickHandler}) {
    return (
        <div className={containerClassNames}>
            <button className={buttonClassNames} onClick={clickHandler}>{text}</button>
        </div>
    );
}

export default Button;