import React from 'react';

function Footer(props) {
    return (
        <footer>
            {/*<ul>
                <li>test</li>
                <li>test</li>
                <li>test</li>
                <li>test</li>
            </ul>*/}
        </footer>
    );
}

export default Footer;