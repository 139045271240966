export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const isBlank = stringToEvaluate => {
    return !stringToEvaluate || stringToEvaluate.trim() === "";
};

export const isNotBlank = stringToEvaluate => {
    return stringToEvaluate && stringToEvaluate.trim() !== "";
};

export const isString = error => {
    return typeof error === 'string';
};

//https://medium.com/@tkssharma/objects-in-javascript-object-assign-deep-copy-64106c9aefab
//let newObj = JSON.parse(JSON.stringify(obj));
export const deepCloneObject = obj => {
    var clone = {};
    for (var i in obj) {
        if (obj[i] != null && typeof (obj[i]) === "object")
            clone[i] = deepCloneObject(obj[i]);
        else
            clone[i] = obj[i];
    }
    return clone;
};

export const pad = n => {
    return n < 10 ? '0' + n : n;
};

export const convertDate = date => {
    return date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate())
        + " " + date.getUTCHours() + ":" + pad(date.getUTCMinutes()) + ":" + pad(date.getUTCSeconds());
};

export const copyObj = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};