import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import * as uiActions from '../../store/actions/uiActions/actions';
import Thumbnail from '../../components/Thumbnail/Thumbnail';
import ChosenThumbnail from '../../components/ChosenThumbnail/ChosenThumbnail';
import UploadForm from '../../components/UploadForm/UploadForm';

import withAuthRedirect from '../../hoc/withAuthRedirect';
import * as URLConstants from '../../utils/Constants';
import axios from '../../utils/Axios';

//https://stackoverflow.com/questions/52064303/reactjs-pass-props-with-redirect-component
function NewVideoUpload(props) {
    const [hasUploadedData, setHasUploadedData] = useState(true);
    const [xPosition, setXPosition] = useState(0);
    const [yPosition, setYPosition] = useState(0);
    const [videoUrl, setVideoUrl] = useState({ videoUrl: null, component: null });
    const [thumbnails, setThumbnails] = useState([]);
    const [showUploadForm, setShowUploadForm] = useState(false);
    const [didSave, setDidSave] = useState(false);
    const [chosenThumbnail, setChosenThumbnail] = useState({ component: null, index: -1, tnail: null});
    const chosenVideo = useSelector(state => state.videoReducer);
    
    const dispatch = useDispatch();

    function mapThumbNails() {
        return props.location.state.thumbnails.map((thumbnail, index) => {
            return (
                <Thumbnail
                    key={index}
                    index={index}
                    thumbnail={thumbnail}
                    onChosen={(index, chosenThumbnail, xPosition, yPosition) => {
                        setXPosition(xPosition);
                        setYPosition(yPosition);
                        setChosenThumbnail({ component: chosenThumbnail, index: index, tnail: thumbnail});
                        setShowUploadForm(true);
                        dispatch(uiActions.backStopShowEvent());
                    }}
                    isChosen={index === chosenThumbnail.index} />
            );
        });
    }

    useEffect(() => {
        dispatch(uiActions.hideUploadEvent());
        dispatch(uiActions.wasUploaded(false));

        setHasUploadedData(false);

        if (props.location.state.videoUrl) {
            setHasUploadedData(true);

            const thumbnail = props.location.state.thumbnails[0];

            setChosenThumbnail({ component: <ChosenThumbnail thumbnail={thumbnail} />, index: 0, tnail: thumbnail});
            setVideoUrl({ videoUrl: props.location.state.videoUrl, component: <div className="video-add-grid__video">Video</div> });
            setThumbnails(mapThumbNails());
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setThumbnails(mapThumbNails());
        // eslint-disable-next-line
    }, [chosenThumbnail]);

    const showUpload = showUploadForm ? <UploadForm onCancel={evt => {
        dispatch(uiActions.backStopHideEvent());
        setShowUploadForm(false);
    }} onSave={(name, description, is_private, category, channel) => {
        const formData = new FormData()

        formData.append('name', name);
        formData.append('id', props.location.state.vidId);
        formData.append('description', description);
        formData.append('is_private', is_private);
        formData.append('category', category);
        formData.append('channel', channel);
        formData.append('video_url', videoUrl.videoUrl);
        formData.append('thumbnail_url', chosenThumbnail.tnail);
        formData.append('xPosition', xPosition);
        formData.append('yPosition', yPosition); 
        
        axios.post(URLConstants.POST_SAVE, formData).then(response => {
            console.log(response.data);
            dispatch(uiActions.backStopHideEvent());
            setShowUploadForm(false);
            setDidSave(true);
        }).catch(error => {
            console.error(error);
        })
    }}/> : null;

    if (!hasUploadedData || didSave) {
        return <Redirect to="/" />;
    }

    if(chosenVideo.videoClicked) {
        return <Redirect to={`/video-page/${chosenVideo.id}`}/>;
    }

    return (
        <React.Fragment>
            {showUpload}
            <div className="video-add-grid">
                {chosenThumbnail.component}
                {thumbnails}
            </div>
        </React.Fragment>
    );
}

export default withAuthRedirect(NewVideoUpload);