export const URL = process.env.NODE_ENV === "development" ? "http://localhost:9000" : "https://famtube.us";

export const BASE_URL = URL + "/service/";

export const LOGIN_URL = "login/";
export const LOGOUT_URL = "logout/";

export const POST_LIKE_URL = "like/";
export const POST_DISLIKE_URL = "dislike/";
export const POST_REPORT_URL = "report/";
export const POST_MAKE_COMMENT = 'post/comment/';
export const POST_DOWNLOAD_URL = "download/";
export const POST_SEARCH_URL = 'search/';
export const POST_INCREASE_VIEWS = 'increase/views/';
export const POST_UPLOAD = 'upload/';
export const POST_SAVE = 'save/';
export const POST_ADJUST_X_Y = 'adjust_x_y/';

export const GET_CONTENT_URL = "content/";
export const GET_CONTENT_LIST_URL = "content/list/";
export const GET_SHARE_URL = 'share/';
export const GET_VIDEOS_URL = 'videos/';
export const GET_PHOTOS_URL = 'photos/';
export const GET_VIDEO_URL = 'get/video/ID/';
export const GET_CONTENT_FOR_TYPE_URL = 'comments/content/TYPE/TYPE_ID/';
export const GET_USER_LIKE_URL = 'content/like/TYPE/TYPE_ID/';
export const GET_USER_DISLIKE_URL = 'content/dislike/TYPE/TYPE_ID/';
export const GET_HAS_PENDING_TASKS = 'has_pending_video_tasks/';
export const GET_HAS_TRASH = 'has_trash/';
export const GET_TRASH = 'get/trash/';
export const GET_PENDING_VIDEOS = 'pending_videos/';
export const POST_TRASH_VIDEO = 'trash_video/';
export const POST_RECOVER_VIDEO = "recover_video/";

export const HTTP_OK = 200;
export const HTTP_BAD_REQUEST = 400;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_PERMISSION_DENIED = 403;
export const HTTP_NOT_FOUND = 404;

export const EVENT_STATE_SUCCESS = 1;
export const EVENT_STATE_ERROR = -1;
export const EVENT_STATE_WARNING = -2;

export const DEFAULT_VIDEO = {
    id: null,
    type: null,
    video: null,
    thumbnail: null,
    name: null,
    description: null,
    views: null,
    likes: null,
    dislikes: null,
    downloads: null,
    hasThumbsUp: false,
    hasThumbsDown: false
};