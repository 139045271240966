import * as actionTypes from './actionTypes';

export const choseVideoAction = video => {
    return { 
        type: actionTypes.CURRENT_VIDEO_EVENT,
        ...video
    };
}

export const didChooseVideo = wasChosen => {
    return {
        type: actionTypes.VIDEO_CHOSEN_EVENT,
        videoClicked: wasChosen
    }
}

export const videoLikeAction = () => {
    return {
        type: actionTypes.THUMBS_UP_EVENT,
        hasThumbsUp: true,
        hasThumbsDown: false
    }
}

export const videoDisLikeAction = () => {
    return {
        type: actionTypes.THUMBS_DOWN_EVENT,
        hasThumbsDown: true,
        hasThumbsUp: false
    }
}

export const videoIncreaseViewsAction = () => {
    return {
        type: actionTypes.VIEWS_INCREASE_EVENT
    }
}