import Axios from 'axios';
import * as URLActions from './Constants';

const instance = Axios.create({
    baseURL: URLActions.BASE_URL
});

instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults.xsrfHeaderName='X-CSRFToken';
instance.defaults.withCredentials = true;

export default instance;