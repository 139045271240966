import React from 'react';
import {useSelector} from 'react-redux';

import {RECOVER} from '../../utils/SVGConstants';

import * as URLConstants from '../../utils/Constants';
import axios from '../../utils/Axios';
import classnames from 'classnames';

function TrashOptions(props) {
    const showTrashOptions = useSelector(state => state.uiReducer.showTrashOptions);

    function onRecoverVideo(evt) {
        const params = new FormData();

        params.append('id', props.id);

        axios.post(URLConstants.POST_RECOVER_VIDEO, params)
        .then(response => {
            if(props.onRecoverVideo) {
                props.onRecoverVideo();
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    let optionsClasses = classnames('video-previews__options');
    let optionListClasses = classnames('video-previews__option-list');
    let isVisible = showTrashOptions.filter(option => option.show && option.id === props.id).length > 0;

    if(isVisible) {
        optionsClasses = classnames('video-previews__options', 'video-previews__options--show');
        optionListClasses = classnames('video-previews__option-list', 'video-previews__option-list--show');
    }

    return (
        <div className={optionsClasses} >
            <ul className={optionListClasses}>
                <li className="video-previews__option-list-item" onClick={onRecoverVideo}>
                    {RECOVER} <span className="video-previews__option-list-item-text">Recover</span>
                </li>
            </ul>
        </div>
    );
}

export default TrashOptions;