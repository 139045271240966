import * as actionTypes from '../../actions/authenticationActions/actionTypes';
import { updateObject } from '../../../utils/CommonUtils';

const initialState = {
    isLoggedIn: false,
    username: null,
    is_admin: false,
    is_staff: false
};

const loginReducer = (state, action) => {
    return updateObject(state, {isLoggedIn: true, username: action.username, is_admin: action.is_admin, is_staff: action.is_staff});
};

const logoutReducer = (state, action) => {
    return updateObject(state, {isLoggedIn: false, username: null, is_admin: false, is_staff: false});
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.LOGIN_EVENT:
            return loginReducer(state, action);
        case actionTypes.LOGOUT_EVENT:
            return logoutReducer(state, action);
        default:
            return state;
    }
};

export default reducer;