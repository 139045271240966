import * as actionTypes from '../../actions/uiActions/actionTypes';
import { updateObject } from '../../../utils/CommonUtils';

import * as Constants from '../../../utils/Constants';

const initialState = {
    showNavBar: true,
    showSidePanel: false,
    didChoosePendingTask: false,
    didChooseTrash: false,
    showBackDrop: false,
    showOverlayMsg: false,
    showAlertMsg: false,
    showSearchMobile: false,
    showSearchDesktop: false,
    showUpload: false,
    showFooter: false,
    uiBlocked: false,
    uploaded: false,
    showReport: false,
    commentClicked: false,
    message: null,
    showVideoOptions: [],
    showTrashOptions: [],
    eventState: Constants.EVENT_STATE_SUCCESS
};

const showBackDrop = (state, action) => {
    return updateObject(state, { showBackDrop: true, uiBlocked: true });
};

const hideBackDrop = (state, action) => {
    return updateObject(state, { showBackDrop: false, uiBlocked: false });
};

const showVideoOptions = (state, action) => {
    return updateObject(state, { showVideoOptions: action.showVideoOptions });
};

const showTrashOptions = (state, action) => {
    return updateObject(state, { showTrashOptions: action.showTrashOptions });
};

const showOverlay = (state, action) => {
    return updateObject(state, {
        showOverlayMsg: true, showBackDrop: true, uiBlocked: true,
        message: action.message, eventState: action.eventState
    });
}

const hideOverlay = (state, action) => {
    return updateObject(state, {
        showOverlayMsg: false, showBackDrop: false, uiBlocked: false,
        message: null, eventState: Constants.EVENT_STATE_SUCCESS
    });
}

const showAlert = (state, action) => {
    return updateObject(state, {
        showAlertMsg: true, showBackDrop: true, uiBlocked: true,
        message: action.message, eventState: action.eventState
    });
}

const hideAlert = (state, action) => {
    return updateObject(state, {
        showAlertMsg: false, showBackDrop: false, uiBlocked: false,
        message: null, eventState: Constants.EVENT_STATE_SUCCESS
    });
}

const showNavBar = (state, action) => {
    return updateObject(state, { showNavBar: true });
}

const hideNavBar = (state, action) => {
    return updateObject(state, { showNavBar: false });
}

const showSidePanel = (state, action) => {
    return updateObject(state, { showSidePanel: true, uiBlocked: true, showBackDrop: true, didChoosePendingTask: false, didChooseTrash: false});
}

const hideSidePanel = (state, action) => {
    return updateObject(state, { showSidePanel: false, uiBlocked: false, showBackDrop: false });
}

const didChoosePendingTask = (state, action) => {
    return updateObject(state, { showSidePanel: false, uiBlocked: false, showBackDrop: false, didChoosePendingTask: true });
}

const didChooseTrash = (state, action) => {
    return updateObject(state, { showSidePanel: false, uiBlocked: false, showBackDrop: false, didChooseTrash: true });
}

const showSearchMobile = (state, action) => {
    return updateObject(state, { showSearchMobile: true, uiBlocked: true, showBackDrop: true });
}

const hideSearchMobile = (state, action) => {
    return updateObject(state, { showSearchMobile: false, uiBlocked: false, showBackDrop: false });
}

const showSearchDesktop = (state, action) => {
    return updateObject(state, { showSearchDesktop: true, uiBlocked: true, showBackDrop: true });
}

const hideSearchDesktop = (state, action) => {
    return updateObject(state, { showSearchDesktop: false, uiBlocked: false, showBackDrop: false });
}

const showReport = (state, action) => {
    return updateObject(state, { showReport: true, uiBlocked: true, showBackDrop: true });
}

const hideReport = (state, action) => {
    return updateObject(state, { showReport: false, uiBlocked: false, showBackDrop: false });
}

const showUpload = (state, action) => {
    return updateObject(state, { showUpload: true, showSidePanel: false, uiBlocked: true, showBackDrop: true });
}

const hideUpload = (state, action) => {
    return updateObject(state, { showUpload: false, showSidePanel: false, uiBlocked: false, showBackDrop: false });
}

const showFooter = (state, action) => {
    return updateObject(state, { showFooter: true });
}

const hideFooter = (state, action) => {
    return updateObject(state, { showFooter: false });
}

const wasUploaded = (state, action) => {
    return updateObject(state, { uploaded: action.wasUploaded });
}

const commentClicked = (state, action) => {
    return updateObject(state, { commentClicked: !state.commentClicked });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UI_ACTION_BACKSTOP_SHOW:
            return showBackDrop(state, action);
        case actionTypes.UI_ACTION_BACKSTOP_HIDE:
            return hideBackDrop(state, action);
        case actionTypes.SHOW_VIDEO_OPTIONS:
            return showVideoOptions(state, action);
        case actionTypes.SHOW_TRASH_OPTIONS:
            return showTrashOptions(state, action);
        case actionTypes.ONLOAD_OVERLAY_EVENT:
            return showOverlay(state, action);
        case actionTypes.UNLOAD_OVERLAY_EVENT:
            return hideOverlay(state, action);
        case actionTypes.ONLOAD_ALERT_OVERLAY_EVENT:
            return showAlert(state, action);
        case actionTypes.UNLOAD_ALERT_OVERLAY_EVENT:
            return hideAlert(state, action);
        case actionTypes.UI_ACTION_NAVBAR_SHOW:
            return showNavBar(state, action);
        case actionTypes.UI_ACTION_NAVBAR_HIDE:
            return hideNavBar(state, action);
        case actionTypes.SHOW_SIDE_PANEL:
            return showSidePanel(state, action);
        case actionTypes.HIDE_SIDE_PANEL:
            return hideSidePanel(state, action);
        case actionTypes.SHOW_SEARCH_MOBILE:
            return showSearchMobile(state, action);
        case actionTypes.HIDE_SEARCH_MOBILE:
            return hideSearchMobile(state, action);
        case actionTypes.SHOW_SEARCH_DESKTOP:
            return showSearchDesktop(state, action);
        case actionTypes.HIDE_SEARCH_DESKTOP:
            return hideSearchDesktop(state, action);
        case actionTypes.SHOW_REPORT:
            return showReport(state, action);
        case actionTypes.HIDE_REPORT:
            return hideReport(state, action);
        case actionTypes.SHOW_FOOTER:
            return showFooter(state, action);
        case actionTypes.HIDE_FOOTER:
            return hideFooter(state, action);
        case actionTypes.SHOW_UPLOAD:
            return showUpload(state, action);
        case actionTypes.HIDE_UPLOAD:
            return hideUpload(state, action);
        case actionTypes.COMMENT_CLICKED:
            return commentClicked(state, action);
        case actionTypes.WAS_UPLOADED:
            return wasUploaded(state, action);
        case actionTypes.DID_CHOOSE_PENDING_TASK:
            return didChoosePendingTask(state, action);
        case actionTypes.DID_CHOOSE_TRASH:
            return didChooseTrash(state, action);
        default:
            return state;
    }
};

export default reducer;