import React from 'react';
import { useSelector } from 'react-redux';

import processingGif from '../../imgs/processing.gif';

import classnames from 'classnames';

export default function NotificationPopup(props) {
    const showOverlayMsg = useSelector(state => state.uiReducer.showOverlayMsg);
    const message = useSelector(state => state.uiReducer.message);
    
    let classNames = classnames("notification");

    if (showOverlayMsg) {
        classNames = classnames("notification", "notification--show");
    }

    const overlay = (
        <div className={classNames}>
            <p>{message}</p>
            <img src={processingGif} alt="Processing gif" />
        </div>
    );

    return (
        <React.Fragment>
            {showOverlayMsg ? overlay : null}
        </React.Fragment>
    );
}
