import React from 'react';
import {Link} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import SearchOther from '../Search/SearchOther';
import ToggleButton from '../ToggleButton/ToggleButton';
import Logout from '../Button/Button';

import * as URLActions from '../../utils/Constants';
import * as authActions from '../../store/actions/authenticationActions/actions';

import famtubeicon from '../../imgs/famtube.png';
import classnames from 'classnames';

import axios from '../../utils/Axios';

export default function NavigationOther(props) {
    const classes = classnames("login", "login--mleft", "login--mright");
    const showNavBar = useSelector(state => state.uiReducer.showNavBar);
    const isLoggedIn = useSelector(state => state.authenticationReducer.isLoggedIn);
    const dispatch = useDispatch();

    function logout() {
        axios.post(URLActions.LOGOUT_URL).then(response => {
            dispatch(authActions.logoutEventAction());
        }).catch(error => {
            console.error(error);
            dispatch(authActions.logoutEventAction());
        });
    }
    
    return (
        <React.Fragment>
            {showNavBar &&
                <nav className="nav-other">
                    <div className="nav-other__left-panel-access">
                        <ToggleButton />
                        <Link className="anchor-img-container" to="/">
                            <img src={famtubeicon} alt="FamTube Icon"/>
                        </Link>
                    </div>
                    <SearchOther />
                    {isLoggedIn ? 
                        <Logout 
                        containerClassNames={classes} 
                        buttonClassNames="login__button" 
                        text="Logout"
                        clickHandler={logout}/>
                    : null}
                </nav>
            }
        </React.Fragment>
    );
}