import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import * as uiActions from '../../store/actions/uiActions/actions';
import classnames from 'classnames';

const TextBoxInputPopup = props => {
    const [text, setText] = useState("");
    const dispatch = useDispatch();

    let className = classnames('textBoxPop');

    if(props.show) {
        className = classnames('textBoxPop', 'textBoxPop--show');
    }

    return (
        <div className={className}>
            <p>{props.title}</p>
            <textarea maxLength={160} value={text} onChange={(evt) => setText(evt.target.value)}/>
            <button className="login__button" onClick={() => {
                props.onClick(text);
                setText("");
            }}>{props.buttonTxt}</button>
            <button className={classnames("login__button", "login__button--cancel")} onClick={() => {
                dispatch(uiActions.hideReportEvent());
                setText("");
            }}>Cancel</button>
        </div>
    );
};

export default TextBoxInputPopup;